import { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';

import { User } from '@gowgates/core';

type UserColProps = PropsWithChildren<{ user: User }>;

const UserCol = ({ user, children }: UserColProps) => {
  if (user.permissions.show) {
    return (
      <Link to={`/users/${user.id}`} className="col-clickable">
        {children}
      </Link>
    );
  }

  return children;
};

export default UserCol;
