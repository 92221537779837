import isPlainObject from 'lodash.isplainobject';
import get from 'lodash.get';
import { camelCase } from 'change-case';

const regex = /%\{(\w+)\}/g;

type ObjectTranslation = { zero?: string; other?: string; one?: string };
type Translations = { [key: string]: string | ObjectTranslation };
let translations: Translations = {};

// TODO: display something more friendly in production than "translation missing"
const notFound = (key: string, notFoundPlaceholder?: string) =>
  notFoundPlaceholder || `translation missing: ${key}`;

type TranslationOptions = { count?: number } & { [key: string]: string | number | boolean } & {
  notFoundPlaceholder?: string;
};

const searchTranslation = (
  key: string,
  options: { isArray?: boolean } & TranslationOptions = {}
): string | string[] => {
  // Converts the key to camel case to avoid doing it everywhere
  const camelKey = key
    .split('.')
    .map((node) => camelCase(node))
    .join('.');

  let translation: ObjectTranslation | string | undefined = get(
    translations,
    camelKey,
    notFound(camelKey, options.notFoundPlaceholder)
  );

  if (isPlainObject(translation)) {
    if (options.count !== undefined) {
      if (options.count === 0) {
        translation =
          (translation as ObjectTranslation).zero || (translation as ObjectTranslation).other;
      } else if (options.count === 1) {
        translation =
          (translation as ObjectTranslation).one || (translation as ObjectTranslation).other;
      } else {
        translation = (translation as ObjectTranslation).other;
      }

      if (!translation) {
        translation = notFound(camelKey, options.notFoundPlaceholder);
      }
    } else {
      // FIXME: "as string" is not totally correct. This should never happen (only if key passed points to an object and not a string without count what is incorrect usage)
      // FIXME: I would tell this should return notFound(camelKey) instead
      return translation as string;
    }
  }

  if (Array.isArray(translation) && !options.isArray) {
    return notFound(camelKey, options.notFoundPlaceholder);
  }

  return translation as string | string[];
};

export const t = (key: string, options: TranslationOptions = {}): string => {
  let translation = searchTranslation(key, options);

  const matches = (translation as string).match(regex);

  if (matches) {
    matches.forEach((match) => {
      const keyword = match.replace('%{', '').replace('}', '');

      if (typeof options[keyword] !== 'undefined') {
        translation = (translation as string).replace(match, String(options[keyword]));
      }
    });
  }

  return translation as string;
};

export const tArray = (key: string): string[] => {
  const translations = searchTranslation(key, { isArray: true });

  return Array.isArray(translations) ? translations : [];
};

export const setTranslations = (newTranslations: Translations) => {
  translations = newTranslations;
};

export const tEnum = (key: string, value?: string) => {
  if (!value) {
    return '-';
  }

  return t(`${key}.${value}`);
};

export const booleanOptions = (
  options: {
    valueAsString?: boolean;
    valueKey?: string;
    labelKey?: string;
    trueLabel?: string;
    falseLabel?: string;
  } = {}
) => {
  const valueAsString = options.valueAsString || false;
  const valueKey = options.valueKey || 'value';
  const labelKey = options.labelKey || 'label';
  const trueValue = valueAsString ? 'true' : true;
  const falseValue = valueAsString ? 'false' : false;
  const trueLabel = options.trueLabel ? options.trueLabel : t('globals.yesNo.true');
  const falseLabel = options.falseLabel || t('globals.yesNo.false');

  return [
    { [valueKey]: trueValue, [labelKey]: trueLabel },
    { [valueKey]: falseValue, [labelKey]: falseLabel }
  ];
};

export default {};
