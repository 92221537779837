import { Form, Modal, Select } from 'antd';

import { FormItem } from '@gowgates/dynamic-fields';
import { t } from '@gowgates/utils';

import ModalFormFooter from '../ModalFormFooter';

type PlaceholderForm = {
  model?: string;
  attribute?: string;
};

type NewPlaceholderModalProps = {
  open: boolean;
  onClose: () => void;
  addPlaceholder: (placeholderText: string) => void;
};

// TODO: this should come from server -- hard coding for prototype and testing
// TODO: it should be passed has prop
const MODEL_OPTIONS = [
  {
    value: 'claim',
    label: 'Claim'
  },
  {
    value: 'client',
    label: 'Client'
  },
  {
    value: 'claimant',
    label: 'Claimant'
  }
];

const ATTRIBUTE_OPTIONS_FOR_MODELS: { [key: string]: { value: string; label: string }[] } = {
  claim: [
    {
      value: 'name',
      label: 'Name'
    },
    {
      value: 'status',
      label: 'Status'
    }
  ],
  client: [
    {
      value: 'first_name',
      label: 'First name'
    },
    {
      value: 'last_name',
      label: 'Last name'
    },
    {
      value: 'address',
      label: 'Address'
    }
  ],
  claimant: [
    {
      value: 'first_name',
      label: 'First name'
    },
    {
      value: 'last_name',
      label: 'Last name'
    }
  ]
};

export const NewPlaceholderModal = ({
  addPlaceholder,
  open,
  onClose
}: NewPlaceholderModalProps) => {
  const [placeholderForm] = Form.useForm();

  const shouldUpdateModel = (prevValues: PlaceholderForm, currentValues: PlaceholderForm) =>
    prevValues.model !== currentValues.model;
  const shouldUpdateAttribute = (prevValues: PlaceholderForm, currentValues: PlaceholderForm) =>
    prevValues.attribute !== currentValues.attribute;
  const shouldUpdateRequiredAttributes = (
    prevValues: PlaceholderForm,
    currentValues: PlaceholderForm
  ) =>
    shouldUpdateModel(prevValues, currentValues) ||
    shouldUpdateAttribute(prevValues, currentValues);

  const closePlaceholderModal = () => {
    placeholderForm.resetFields();

    onClose();
  };

  return (
    <Modal
      title={t('globals.add_placeholder')}
      open={open}
      onCancel={closePlaceholderModal}
      onClose={closePlaceholderModal}
      footer={null}
    >
      <Form
        form={placeholderForm}
        layout="vertical"
        onFinish={(data) => {
          closePlaceholderModal();

          addPlaceholder(`%{${data.model}.${data.attribute}}`.toUpperCase());
        }}
      >
        <FormItem name="model" required label="Model">
          <Select
            options={MODEL_OPTIONS}
            onChange={() => {
              placeholderForm.setFieldValue('attribute', null);
            }}
          />
        </FormItem>

        <Form.Item noStyle shouldUpdate={shouldUpdateModel}>
          {({ getFieldValue }) => {
            const model = getFieldValue('model');
            const attributes = ATTRIBUTE_OPTIONS_FOR_MODELS[model];

            if (!model || !attributes) return;

            return (
              <FormItem name="attribute" required label="Attribute">
                <Select options={attributes} />
              </FormItem>
            );
          }}
        </Form.Item>

        <Form.Item noStyle shouldUpdate={shouldUpdateRequiredAttributes}>
          {({ getFieldValue }) => {
            const model = getFieldValue('model');
            const attribute = getFieldValue('attribute');

            return (
              <ModalFormFooter closeModal={closePlaceholderModal} disabled={!model || !attribute} />
            );
          }}
        </Form.Item>
      </Form>
    </Modal>
  );
};
