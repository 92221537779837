import React from 'react';
import { Row, Col, Form, Checkbox, Button, App } from 'antd';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { addErrorsFromAPIInForm, t } from '@gowgates/utils';
import { getPermission, updatePermission } from '../../api/endpoints';
import InnerBox from '../../components/InnerBox';
import { showSavedMessage } from '../../utils/messages';
import FullLoader from '../../components/FullLoader';

const PermissionForm = () => {
  const { message } = App.useApp();
  const permissionId = Number(useParams().permissionId);
  const [form] = Form.useForm();
  const queryClient = useQueryClient();

  const { isLoading, data: permission } = useQuery({
    queryKey: ['permission', permissionId],
    queryFn: () => getPermission(permissionId)
  });

  const { isPending: isMutating, mutate } = useMutation({
    mutationFn: (values) => updatePermission(permissionId, values),
    onError: (error) => addErrorsFromAPIInForm({ error, form }),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['myPermissions'] });
      queryClient.setQueryData(['permission', permissionId], data);
      showSavedMessage(message);
    }
  });

  if (isLoading) {
    return <FullLoader />;
  }

  const isDisabled = isMutating || !permission.permissions.update;

  return (
    <Form form={form} onFinish={mutate} initialValues={permission} disabled={isDisabled}>
      <Form.Item name="settings">
        <Checkbox.Group style={{ width: '100%' }}>
          <InnerBox title="Dashboard" className="w-100">
            <Row>
              <Col span={6}>
                <Checkbox value="global_statistics">Show global statistics</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value="users_statistics">Show other user's stats</Checkbox>
              </Col>
            </Row>
          </InnerBox>

          <InnerBox title="Claims" className="w-100">
            <Row>
              <Col span={6}>
                <Checkbox value="claims_index">List</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value="claims_show">Show</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value="claims_create">Create</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value="claims_update">Update</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value="claims_destroy">Delete</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value="claims_assign_to_me">Assign to self</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value="claims_assign">Assign to others</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value="claims_update_insurer_number">Update insurer number</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value="claims_update_bank_details">Update bank details</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value="claims_update_reserve">Update claim reserve</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value="claims_add_complaint">Add complaint</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value="claims_remove_complaint">Remove complaint</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value="claims_add_importance">Add priority</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value="claims_remove_importance">Remove priority</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value="claims_show_data">Show claim details</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value="claims_show_client_details">Show client details</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value="claims_show_bank_details">Show bank details</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value="claims_show_personal_details">Show claimant details</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value="claims_show_documents">Show documents</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value="claims_create_documents">Create documents</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value="claims_update_documents">Update documents</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value="claims_destroy_documents">Delete documents</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value="claims_show_items">Show expenses</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value="claims_create_items">Create expenses</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value="claims_update_items">Update expenses</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value="claims_destroy_items">Delete expenses</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value="claims_create_sections">Create sections</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value="claims_update_sections">Update sections</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value="claims_destroy_sections">Delete sections</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value="claims_show_finance">Show finance</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value="claims_show_notes">Show notes</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value="claims_create_notes">Create notes</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value="claims_export_notes">Export notes</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value="claims_destroy_notes">Delete notes</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value="claims_show_tasks">Show tasks</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value="claims_create_tasks">Create tasks manually</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value="claims_update_tasks">Update tasks</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value="claims_show_other_roles_tasks">Show other roles' tasks</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value="claims_complete_other_roles_tasks">
                  Complete other roles' tasks
                </Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value="claims_cancel_other_roles_tasks">
                  Cancel other roles' tasks
                </Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value="claims_show_others_tasks">Show other users' tasks</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value="claims_complete_others_tasks">
                  Complete other users' tasks
                </Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value="claims_cancel_others_tasks">Cancel other users' tasks</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value="claims_reassign_tasks">Reassign tasks to others</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value="claims_reassign_tasks_to_me">Reassign tasks to self</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value="claims_reopen">Reopen claim</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value="claims_export">Export</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value="claims_export_tasks">Export tasks</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value="claims_resend_emails">Resend emails</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value="claims_withdraw">Withdraw claim</Checkbox>
              </Col>
            </Row>
          </InnerBox>

          <InnerBox title="Users" className="w-100">
            <Row>
              <Col span={6}>
                <Checkbox value="users_index">List</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value="users_show">Show</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value="users_create">Create</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value="users_update">Update</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value="users_update_role">Update Role</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value="users_show_permissions">Show Permissions</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value="users_update_permissions">Update Permissions</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value="users_reset_password">Reset password</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value="users_disable_otp">Disable two-factor authentication</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value="users_block">Block</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value="users_unblock">Unblock</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value="users_send_confirmation">
                  Resend confirmation instructions
                </Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value="users_confirm">Confirm</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value="users_send_unlock">Resend unlock instructions</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value="users_unlock">Unlock</Checkbox>
              </Col>
            </Row>
          </InnerBox>

          <InnerBox title="Reports" className="w-100">
            <Row>
              <Col span={6}>
                <Checkbox value="claims_all">Reports</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value="claims_export_all">Export Report</Checkbox>
              </Col>
            </Row>
          </InnerBox>

          <InnerBox title="User roles" className="w-100">
            <Row>
              <Col span={6}>
                <Checkbox value="roles_show">Show</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value="roles_create">Create</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value="roles_update">Update</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value="roles_destroy">Delete</Checkbox>
              </Col>
            </Row>
          </InnerBox>

          <InnerBox title="Permissions" className="w-100">
            <Row>
              <Col span={6}>
                <Checkbox value="permissions_show">Show</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value="permissions_create">Create</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value="permissions_update">Update</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value="permissions_destroy">Delete</Checkbox>
              </Col>
            </Row>
          </InnerBox>

          <InnerBox title="Integrations" className="w-100">
            <Row>
              <Col span={6}>
                <Checkbox value="integrations_show">Show</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value="integrations_create">Create</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value="integrations_update">Update</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value="integrations_destroy">Delete</Checkbox>
              </Col>
            </Row>
          </InnerBox>

          <InnerBox title="File Config" className="w-100">
            <Row>
              <Col span={6}>
                <Checkbox value="file_config_manage">Manage</Checkbox>
              </Col>
            </Row>
          </InnerBox>

          <InnerBox title="Claim structures" className="w-100">
            <Row>
              <Col span={6}>
                <Checkbox value="claim_structures_manage">Manage</Checkbox>
              </Col>
            </Row>
          </InnerBox>

          <InnerBox title="Processes" className="w-100">
            <Row>
              <Col span={6}>
                <Checkbox value="journeys_manage">Manage</Checkbox>
              </Col>
            </Row>
          </InnerBox>

          <InnerBox title="Lists" className="w-100">
            <Row>
              <Col span={6}>
                <Checkbox value="list_items_manage">Manage</Checkbox>
              </Col>
            </Row>
          </InnerBox>

          <InnerBox title="Payments" className="w-100">
            <Row>
              <Col span={6}>
                <Checkbox value="list_payments">List</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value="export_payments">Export payments</Checkbox>
              </Col>
            </Row>
          </InnerBox>

          <InnerBox title="Tenant" className="w-100">
            <Row>
              <Col span={6}>
                <Checkbox value="update_tenant">Update tenant</Checkbox>
              </Col>
            </Row>
          </InnerBox>

          <InnerBox title="Email templates" className="w-100">
            <Row>
              <Col span={6}>
                <Checkbox value="email_templates_index">List</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value="email_templates_show">Show</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value="email_templates_create">Create</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value="email_templates_update">Update</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value="email_templates_destroy">Delete</Checkbox>
              </Col>
            </Row>
          </InnerBox>
        </Checkbox.Group>
      </Form.Item>

      <div className="d-flex justify-content-end">
        {permission.permissions.update && (
          <Button type="primary" htmlType="submit" loading={isDisabled}>
            {t('globals.save')}
          </Button>
        )}
      </div>
    </Form>
  );
};

export default PermissionForm;
