import { AxiosError } from 'axios';

import { UseCreateMutationProps, addErrorsFromAPIInForm } from '@gowgates/utils';
import { QueryKey, useQueryClient } from '@tanstack/react-query';

type Props<ReturnType, ErrorType, PayloadType> = Omit<
  UseCreateMutationProps<ReturnType, PayloadType, ErrorType>,
  'onError'
> & {
  invalidateQueries?: QueryKey[];
  onError?: (error: AxiosError<ErrorType>) => void;
};

export const useHandleMutations = <
  ReturnType = undefined,
  ErrorType = unknown,
  PayloadType = unknown
>({
  form,
  invalidateQueries,
  onSuccess,
  onError
}: Props<ReturnType, ErrorType, PayloadType>) => {
  const queryClient = useQueryClient();

  const handleSuccess = (data: ReturnType, variables: PayloadType) => {
    invalidateQueries?.forEach((invalidateQuery) => {
      queryClient.invalidateQueries({ queryKey: invalidateQuery });
    });
    if (onSuccess) onSuccess(data, variables);
  };

  const handleError = (error: AxiosError<ErrorType>) => {
    if (form) addErrorsFromAPIInForm({ error, form });
    if (onError) onError(error);
  };

  return {
    handleSuccess,
    handleError
  };
};
