import { Modal } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

type LoadingHoverProps = {
  open?: boolean;
};

const LoadingHover = ({ open = false }: LoadingHoverProps) => (
  <Modal open={open} footer={null} closable={false} centered className="loading-modal">
    <LoadingOutlined />
  </Modal>
);

export default LoadingHover;
