import dayjs, { Dayjs, OpUnitType, QUnitType } from 'dayjs';
import { DateFormatNames } from '../types';
import { EMPTY_TEXT } from './text';

export const displayFormats: { [key in DateFormatNames]: string } = {
  minute: 'DD MMM YYYY HH:mm',
  day: 'DD MMM YYYY',
  month: 'MMM YYYY',
  year: 'YYYY',
  time: 'HH:mm'
};

export const inputFormats: { [key in DateFormatNames]: string } = {
  minute: 'DD-MM-YYYY HH:mm',
  day: 'DD-MM-YYYY',
  month: 'MM-YYYY',
  year: 'YYYY',
  time: 'HH:mm'
};

export const dbFormats: { [key in DateFormatNames]: string } = {
  minute: 'YYYY-MM-DD HH:mm',
  day: 'YYYY-MM-DD',
  month: 'YYYY-MM',
  year: 'YYYY',
  time: 'HH:mm'
};

type Date = Dayjs | string;

export const formatDate = (inputDate?: Date, format: DateFormatNames = 'day') => {
  if (!inputDate) {
    return EMPTY_TEXT;
  }

  return dayjs(inputDate).format(displayFormats[format]);
};

export const parseDate = (value?: Date, format: DateFormatNames = 'day') => {
  if (!value) {
    return null;
  }

  return dayjs(value, dbFormats[format]);
};

export const datesDiff = (dateOne?: Date, dateTwo?: Date, unit?: QUnitType | OpUnitType) =>
  dateOne && dateTwo ? dayjs(dateOne).diff(dateTwo, unit) : 0;
