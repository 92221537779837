import { PropsWithChildren } from 'react';
import { Tag } from 'antd';

import { t } from '@gowgates/utils';

import { Claim } from '../../../types';

type ClaimStatusTagProps = {
  claim: Claim;
  TooltipComponent?: React.FC<PropsWithChildren<{ title?: string }>>;
};

const statuses: { [key: string]: string } = {
  open: 'processing',
  closed: 'green',
  draft: 'warning'
};

const ClaimStatusTag = ({
  claim,
  TooltipComponent = ({ children }) => children
}: ClaimStatusTagProps) => (
  <Tag color={statuses[claim.status]}>
    <TooltipComponent title={t(`claim.statuses.${claim.status}`)}>
      {t(`claim.statuses.${claim.status}`)}
    </TooltipComponent>
  </Tag>
);

export default ClaimStatusTag;
