import { useMutation } from '@tanstack/react-query';

import { extractData, client } from '@gowgates/api-client';
import { AntFileType, UrlId, UseUpdateMutationProps } from '@gowgates/utils';

import { useHandleMutations } from '../useHandleMutation';
import { handleUrlId } from '../../api';
import { User } from '../../types';
import { userMeQueryKey } from '../useMeQuery';

type UserPayload = Partial<Omit<User, 'avatar'> & { avatar: AntFileType }>;
const mutationFn = (id: UrlId, user: UserPayload) =>
  handleUrlId<User>(id, (id) =>
    client()
      .put(`users/${id}`, { data: { user } })
      .then(extractData<User>)
  );

type Props = UseUpdateMutationProps<User, UserPayload> & { isMe?: boolean };

export const useUpdateUserMutation = ({ id, form, onSuccess, onError, isMe = false }: Props) => {
  const { handleSuccess, handleError } = useHandleMutations<User, User, UserPayload>({
    form,
    invalidateQueries: [['users', id], ...(isMe ? [userMeQueryKey] : [])],
    onSuccess,
    onError
  });

  const { mutate, ...mutation } = useMutation({
    mutationFn: (values: UserPayload) => mutationFn(id, values),
    onError: handleError,
    onSuccess: handleSuccess
  });

  return {
    updateUser: mutate,
    ...mutation
  };
};
