import { PropsWithChildren, useEffect } from 'react';
import { ScrollRestoration, useLocation } from 'react-router-dom';

import { useAuth } from '../../contexts';

type AppContainerProps = PropsWithChildren;

export const AppContainer = ({ children }: AppContainerProps) => {
  const { isUserSignedIn, setAfterLoginPath } = useAuth();
  const location = useLocation();

  useEffect(() => {
    if (!isUserSignedIn) {
      setAfterLoginPath(location.pathname);
      window.location.href = '/login';
    }
  }, [isUserSignedIn, setAfterLoginPath, location]);

  return isUserSignedIn ? (
    <>
      {children}
      <ScrollRestoration />
    </>
  ) : null;
};
