import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { Form, Button, Space, App } from 'antd';
import { isUnprocessableEntity } from '@gowgates/api-client';
import { t, scrollToFirstError, addErrorsFromAPIInForm } from '@gowgates/utils';

const FormElement = ({
  children,
  cancelEdit,
  model,
  updateModel,
  successMessage = 'globals.saved',
  queryDataKey,
  onSuccess = () => {}
}) => {
  const { message } = App.useApp();
  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  useEffect(() => {
    const onKeyup = (event) => {
      if (event.key === 'Escape') {
        cancelEdit();
      }
    };

    window.addEventListener('keyup', onKeyup);

    // runs in component unmount
    return () => window.removeEventListener('keyup', onKeyup);
  }, []);

  const { isPending: isLoading, mutate } = useMutation({
    mutationFn: updateModel,
    onError: (error) => {
      addErrorsFromAPIInForm({ error, form });

      if (isUnprocessableEntity(error)) {
        scrollToFirstError();
      }
    },
    onSuccess: (data) => {
      cancelEdit();
      message.success(t(successMessage));
      onSuccess(data);
      if (queryDataKey) {
        queryClient.setQueryData(queryDataKey, data);
      }
    }
  });

  return (
    <Form
      form={form}
      initialValues={model}
      layout="vertical"
      onFinish={mutate}
      disabled={isLoading}
    >
      {/* Adds the ant form element as props to children */}
      {/* TODO: Replace with `const form = Form.useFormInstance();` */}
      {React.cloneElement(children, { form, disabled: isLoading })}

      <div className="d-flex justify-content-end">
        <Space>
          <Button onClick={cancelEdit} disabled={isLoading}>
            {t('globals.cancel')}
          </Button>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            {t('globals.save')}
          </Button>
        </Space>
      </div>
    </Form>
  );
};

FormElement.propTypes = {
  children: PropTypes.node.isRequired,
  cancelEdit: PropTypes.func.isRequired,
  model: PropTypes.object.isRequired,
  updateModel: PropTypes.func.isRequired,
  successMessage: PropTypes.string,
  queryDataKey: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onSuccess: PropTypes.func
};

export default FormElement;
