import { Row, Col } from 'antd';

import PageContent from './PageContent';

type PageContentWithSummaryProps = {
  children: React.ReactNode;
  nav?: React.ReactNode;
  summary?: React.ReactNode;
};

const PageContentWithSummary = ({ nav, summary, children }: PageContentWithSummaryProps) => (
  <PageContent>
    <Row gutter={30}>
      <Col span={18}>
        <section className="box overlap-header">
          {nav}

          {children}
        </section>
      </Col>
      <Col span={6}>{summary}</Col>
    </Row>
  </PageContent>
);

export default PageContentWithSummary;
