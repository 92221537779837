import React from 'react';
import { Button, Form, Input, Switch, App } from 'antd';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { useParams } from 'react-router';
import { FormItem, FilePicker } from '@gowgates/dynamic-fields';
import { addErrorsFromAPIInForm, t } from '@gowgates/utils';
import { usePageTitle } from '@gowgates/core';
import { updateDocumentStructure } from '../../../../api/endpoints';
import { showSavedMessage } from '../../../../utils/messages';
import useAppConfigs from '../../../../hooks/useAppConfigs';

const DocumentStructureDetails = () => {
  const { message } = App.useApp();
  const structureId = Number(useParams().structureId);
  const documentId = Number(useParams().documentId);
  const queryClient = useQueryClient();
  const documentStructure = queryClient.getQueryData(['documentStructure', documentId]);
  const { appConfigs } = useAppConfigs();

  usePageTitle(
    `${documentStructure.label} - ${t('globals.details')} | ${t(
      'activerecord.models.claimStructure.other'
    )}`
  );

  const [form] = Form.useForm();

  const { isPending: isLoading, mutate } = useMutation({
    mutationFn: (values) => updateDocumentStructure(structureId, documentId, values),
    onError: (error) => addErrorsFromAPIInForm({ error, form }),
    onSuccess: (data) => {
      queryClient.setQueryData(['documentStructure', documentId], data);
      showSavedMessage(message);
    }
  });

  const shouldUpdateAllowMultiple = (prevValues, currentValues) =>
    prevValues.allowMultiple !== currentValues.allowMultiple;

  return (
    <Form
      form={form}
      onFinish={mutate}
      initialValues={documentStructure}
      layout="vertical"
      disabled={isLoading}
    >
      <FormItem name="label" model="documentStructure" required>
        <Input />
      </FormItem>

      <FormItem name="file" model="documentStructure">
        <FilePicker accept={appConfigs.global?.allowedMimeTypes?.flat()?.join(',')} />
      </FormItem>

      <FormItem name="required" model="documentStructure" valuePropName="checked">
        <Switch />
      </FormItem>

      <FormItem name="allowMultiple" model="documentStructure" valuePropName="checked">
        <Switch />
      </FormItem>

      <Form.Item noStyle shouldUpdate={shouldUpdateAllowMultiple}>
        {({ getFieldValue }) =>
          getFieldValue('allowMultiple') && (
            <FormItem name="autoFill" model="documentStructure" valuePropName="checked">
              <Switch />
            </FormItem>
          )
        }
      </Form.Item>

      <div className="d-flex justify-content-end">
        <Button type="primary" htmlType="submit" loading={isLoading}>
          {t('globals.save')}
        </Button>
      </div>
    </Form>
  );
};

export default DocumentStructureDetails;
