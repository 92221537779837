import { createBrowserRouter, Navigate } from 'react-router-dom';
import { AppContainer, ErrorBoundary, authRoutes } from '@gowgates/core';

import Home from './layouts/home';
import UsersStatistics from './layouts/home/UsersStatistics';
import Claims from './layouts/claims';
import Claim from './layouts/claim';
import Tasks from './layouts/tasks';
import Users from './layouts/users';
import CreateUser from './layouts/user/create';
import User from './layouts/user';
import ClaimsReport from './layouts/reports/claims';
import PaymentsReport from './layouts/reports/payments';
import ProgressReport from './layouts/reports/progress';
import TasksReport from './layouts/reports/tasks';
import ClaimStructures from './layouts/structures';
import ClaimStructure from './layouts/structure';
import Otp from './layouts/otp';
import ProfileContainer from './layouts/profile/container';
import ClaimDetails from './layouts/claim/show/ClaimDetails';
import PersonalDetails from './layouts/claim/show/PersonalDetails';
import ClaimDocuments from './layouts/claim/show/Documents';
import ClaimFinance from './layouts/claim/show/Finance';
import ClaimItems from './layouts/claim/show/Items';
import ClaimNotes from './layouts/claim/show/Notes';
import ClaimTasks from './layouts/claim/show/Tasks';
import UnderwritingDetails from './layouts/claim/create/Underwriting';
import ClaimSections from './layouts/claim/create/Sections';
import CreateClaimDetails from './layouts/claim/create/ClaimDetails';
import ClaimUploads from './layouts/claim/create/Uploads';
import ClaimDeclaration from './layouts/claim/create/Declaration';
import ClaimNotesList from './layouts/claim/show/Notes/List';
import ClaimNote from './layouts/claim/show/Note';
import ProfileForm from './layouts/profile/Form';
import Password from './layouts/profile/Password';
import ProfileEmail from './layouts/profile/ProfileEmail';
import StructureDetails from './layouts/structure/Details';
import ClaimStructureFields from './layouts/structure/Claim';
import SectionStructures from './layouts/structure/Sections';
import DocumentStructures from './layouts/structure/Documents';
import ReportStructures from './layouts/structure/Reports';
import SectionStructure from './layouts/structure/Section';
import SectionStructureDetails from './layouts/structure/Section/Details';
import SectionStructureFields from './layouts/structure/Section/Fields';
import SectionStructureItems from './layouts/structure/Section/Items';
import DocumentStructure from './layouts/structure/Document';
import DocumentStructureDetails from './layouts/structure/Document/Details';
import DocumentStructureFields from './layouts/structure/Document/Fields';
import ReportStructure from './layouts/structure/Report';
import ReportStructureDetails from './layouts/structure/Report/Details';
import ReportStructureFields from './layouts/structure/Report/Fields';
import Journeys from './layouts/journeys';
import Journey from './layouts/journey';
import JourneyDetails from './layouts/journey/Details';
import TaskStructures from './layouts/journey/Tasks';
import TaskStructure from './layouts/journey/Task';
import FileConfig from './layouts/file-config';
import FileFields from './layouts/file-config/File';
import ClaimantFields from './layouts/file-config/Claimant';
import ClientFields from './layouts/file-config/Client';
import CoverFields from './layouts/file-config/Cover';
import Integrations from './layouts/integrations';
import Lists from './layouts/lists';
import ListItems from './layouts/list-items';
import Roles from './layouts/roles';
import Role from './layouts/role';
import Permissions from './layouts/permissions';
import Permission from './layouts/permission';
import PermissionForm from './layouts/permission/PermissionForm';
import PermissionUsersTable from './layouts/permission/PermissionUsersTable';
import { EditTask as EditTaskFrontOffice } from './layouts/frontoffice/EditTask';
import Payments from './layouts/payments';
import { GeneralConfig } from './layouts/general-config';
import CompleteFormBulk from './layouts/complete-form-bulk';
import { EmailTemplates } from './layouts/emailTemplates';
import { EmailTemplate } from './layouts/emailTemplates/EmailTemplate';
import Backoffice from './layouts/backoffice';

const router = createBrowserRouter([
  ...authRoutes({ errorElement: <ErrorBoundary homepagePath="/" /> }),
  {
    path: 'frontoffice/tasks/:taskUuid',
    element: <EditTaskFrontOffice />,
    errorElement: <ErrorBoundary />
  },
  {
    path: '/',
    element: (
      <AppContainer>
        <Backoffice />
      </AppContainer>
    ),
    errorElement: <ErrorBoundary homepagePath="/" />,
    children: [
      { path: '', element: <Home /> },
      { path: 'users-statistics', element: <UsersStatistics /> },

      { path: 'tasks', element: <Tasks /> },

      { path: 'claims', element: <Claims /> },
      // { path: 'claims/new', element: <CreateClaim /> },
      {
        path: 'claims/:claimId',
        element: <Claim />,
        children: [
          { path: 'claim', element: <ClaimDetails /> },
          { path: 'items', element: <ClaimItems /> },
          { path: 'client', element: <PersonalDetails /> },
          { path: 'documents', element: <ClaimDocuments /> },
          { path: 'finance', element: <ClaimFinance /> },
          {
            path: 'notes',
            element: <ClaimNotes />,
            children: [
              { path: '', element: <ClaimNotesList /> },
              { path: ':noteId', element: <ClaimNote /> }
            ]
          },
          { path: 'tasks', element: <ClaimTasks /> },

          { path: 'underwriting', element: <UnderwritingDetails /> },
          { path: 'claim-details', element: <CreateClaimDetails /> },
          { path: 'sections', element: <ClaimSections /> },
          { path: 'uploads', element: <ClaimUploads /> },
          { path: 'declaration', element: <ClaimDeclaration /> }
        ]
      },

      { path: 'users', element: <Users /> },
      { path: 'users/new', element: <CreateUser /> },
      { path: 'users/:userId', element: <User /> },

      { path: 'reports/claims', element: <ClaimsReport /> },
      { path: 'reports/progress', element: <ProgressReport /> },
      { path: 'reports/tasks', element: <TasksReport /> },
      { path: 'reports/payments', element: <PaymentsReport /> },

      { path: 'structures', element: <ClaimStructures /> },

      {
        path: 'structures/:structureId',
        element: <ClaimStructure />,
        children: [
          { path: '', element: <Navigate to="details" replace /> },
          { path: 'details', element: <StructureDetails /> },
          { path: 'claim', element: <ClaimStructureFields /> },
          { path: 'sections', element: <SectionStructures /> },
          { path: 'documents', element: <DocumentStructures /> },
          { path: 'reports', element: <ReportStructures /> }
        ]
      },

      {
        path: 'structures/:structureId/sections/:sectionId',
        element: <SectionStructure />,
        children: [
          { path: '', element: <Navigate to="details" replace /> },
          { path: 'details', element: <SectionStructureDetails /> },
          { path: 'fields', element: <SectionStructureFields /> },
          { path: 'items', element: <SectionStructureItems /> }
        ]
      },

      {
        path: 'structures/:structureId/documents/:documentId',
        element: <DocumentStructure />,
        children: [
          { path: '', element: <Navigate to="details" replace /> },
          { path: 'details', element: <DocumentStructureDetails /> },
          { path: 'fields', element: <DocumentStructureFields /> }
        ]
      },

      {
        path: 'structures/:structureId/reports/:reportId',
        element: <ReportStructure />,
        children: [
          { path: '', element: <Navigate to="details" replace /> },
          { path: 'details', element: <ReportStructureDetails /> },
          { path: 'fields', element: <ReportStructureFields /> }
        ]
      },

      { path: 'processes', element: <Journeys /> },
      {
        path: 'processes/:journeyId',
        element: <Journey />,
        children: [
          { path: '', element: <Navigate to="details" replace /> },
          { path: 'details', element: <JourneyDetails /> },
          { path: 'claim-tasks', element: <TaskStructures entity="claim" /> },
          { path: 'item-tasks', element: <TaskStructures entity="item" /> },
          { path: 'document-tasks', element: <TaskStructures entity="document" /> },
          { path: 'claim-tasks/:taskId', element: <TaskStructure /> },
          { path: 'item-tasks/:taskId', element: <TaskStructure /> },
          { path: 'document-tasks/:taskId', element: <TaskStructure /> }
        ]
      },
      { path: 'general-config', element: <GeneralConfig /> },

      { path: 'profile/otp', element: <Otp /> },
      {
        path: 'profile',
        element: <ProfileContainer />,
        children: [
          { path: '', element: <Navigate to="details" replace /> },
          { path: 'details', element: <ProfileForm /> },
          { path: 'password', element: <Password /> },
          { path: 'email', element: <ProfileEmail /> }
        ]
      },

      { path: 'integrations', element: <Integrations /> },
      { path: 'lists', element: <Lists /> },
      { path: 'lists/:listItemId', element: <ListItems /> },
      { path: 'email-templates', element: <EmailTemplates /> },
      { path: 'email-templates/:emailTemplateId', element: <EmailTemplate /> },
      {
        path: 'file-config',
        element: <FileConfig />,
        children: [
          { path: '', element: <Navigate to="file" replace /> },
          { path: 'file', element: <FileFields /> },
          { path: 'claimant', element: <ClaimantFields /> },
          { path: 'client', element: <ClientFields /> },
          { path: 'cover', element: <CoverFields /> }
        ]
      },
      { path: 'roles', element: <Roles /> },
      { path: 'roles/:roleId', element: <Role /> },
      {
        path: 'permissions',
        element: <Permissions />
      },
      {
        path: 'permissions/:permissionId',
        element: <Permission />,
        children: [
          { path: 'permissions', element: <PermissionForm /> },
          { path: 'users', element: <PermissionUsersTable /> }
        ]
      },
      { path: 'payments', element: <Payments /> },
      { path: 'complete-form-bulk', element: <CompleteFormBulk /> }
    ]
  }
]);

export default router;
