import { Form } from 'antd';
import { Field } from '../../../types';
import { useSaveFieldMutation } from '../../../hooks/fields/useSaveFieldMutation';
import { useFormBuilder } from '../../../hooks/useFormBuilder';
import { hasValidation } from '../../../utils';
import { Main } from './Main';
import { Footer } from './Footer';
import { Header } from './Header';
import { Validations } from './Validations';
import { Condition } from './Condition';

export const EditField = ({ field }: { field: Partial<Field> }) => {
  const [form] = Form.useForm<Field>();
  const { endEditing } = useFormBuilder();

  const { save } = useSaveFieldMutation({ field, form, onSuccess: endEditing });

  const initialValues = { ...field, hasDescription: !!field.description };

  const conditionalUpdated = (prevValues: Field, currentValues: Field) =>
    prevValues.conditional !== currentValues.conditional;

  const validationsUpdated = (prevValues: Field, currentValues: Field) =>
    prevValues.validations !== currentValues.validations || prevValues.type !== currentValues.type;

  const isLoading = false;

  return (
    <article className="field-container field-container-edit">
      <Form form={form} onFinish={save} initialValues={initialValues} disabled={isLoading}>
        <Header field={field} />
        <Main field={field} />
        <Form.Item noStyle shouldUpdate={conditionalUpdated}>
          {({ getFieldValue }) => getFieldValue('conditional') && <Condition field={field} />}
        </Form.Item>

        <Form.Item noStyle shouldUpdate={validationsUpdated}>
          {({ getFieldValue }) =>
            getFieldValue('validations') &&
            hasValidation(getFieldValue('type')) && <Validations field={field} />
          }
        </Form.Item>

        <Footer saving={isLoading} />
      </Form>
    </article>
  );
};
