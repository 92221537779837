import React from 'react';
import PropTypes from 'prop-types';
import { Modal, App } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router';
import { t, DEFAULT_CLAIMS_PATH } from '@gowgates/utils';
import { sendClaimAction, deleteClaim } from '../../../../api/endpoints';
import useClaim from '../../../../hooks/useClaim';
import { backendURL } from '../../../../utils/tenants';
import PageContextMenu from '../../../../components/PageContextMenu';
import useAppConfigs from '../../../../hooks/useAppConfigs';

const ClaimContextMenu = ({ claim }) => {
  const { message } = App.useApp();
  const { refreshClaim } = useClaim();
  const navigate = useNavigate();
  const { appConfigs } = useAppConfigs();

  const actionMutation = useMutation({
    mutationFn: (values) => sendClaimAction(claim.id, values.action, values.params),
    onSuccess: refreshClaim
  });

  const destroyMutation = useMutation({
    mutationFn: () => deleteClaim(claim.id),
    onSuccess: () => {
      message.success(t('claim.destroyed', { id: claim.id }));
      navigate(DEFAULT_CLAIMS_PATH);
    }
  });

  const addComplaint = (event) => {
    actionMutation.mutate({ action: 'add_complaint', params: { complaint: event.key } });
  };

  const removeComplaint = () => {
    actionMutation.mutate({ action: 'remove_complaint' });
  };

  const addImportance = (event) => {
    actionMutation.mutate({ action: 'add_importance', params: { importance: event.key } });
  };

  const removeImportance = () => {
    actionMutation.mutate({ action: 'remove_importance' });
  };

  const reopen = () => {
    actionMutation.mutate({ action: 'reopen' });
  };

  const withdraw = (event) => {
    actionMutation.mutate({ action: 'withdraw', params: { withdrawReason: event.key } });
  };

  const destroy = () => {
    Modal.confirm({
      title: t('claim.confirmDestroy'),
      icon: <ExclamationCircleOutlined />,
      okText: t('globals.yesNo.true'),
      okType: 'danger',
      cancelText: t('globals.yesNo.false'),
      onOk() {
        destroyMutation.mutate();
      }
    });
  };

  const items = [];
  if (claim.permissions.export) {
    items.push({
      key: 'export',
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`${backendURL}/claims/${claim.id}/export.pdf`}
        >
          {t('claim.export')}
        </a>
      )
    });
  }

  if (claim.permissions.addComplaint) {
    items.push({
      key: 'addComplaint',
      label: t('claim.addComplaint'),
      onClick: addComplaint,
      children: appConfigs.claim.complaints.map((complaint) => ({
        ...complaint,
        key: complaint.value
      }))
    });
  }

  if (claim.permissions.removeComplaint) {
    items.push({
      key: 'removeComplaint',
      label: t('claim.removeComplaint'),
      onClick: removeComplaint
    });
  }

  if (claim.permissions.addImportance) {
    items.push({
      key: 'addImportance',
      label: t('claim.addImportance'),
      onClick: addImportance,
      children: appConfigs.claim.importances.map((importance) => ({
        ...importance,
        key: importance.value
      }))
    });
  }

  if (claim.permissions.removeImportance) {
    items.push({
      key: 'removeImportance',
      label: t('claim.removeImportance'),
      onClick: removeImportance
    });
  }

  if (claim.permissions.reopen) {
    items.push({ key: 'reopen', label: t('claim.reopen'), onClick: reopen });
  }

  if (claim.permissions.withdraw) {
    items.push({
      key: 'withdraw',
      label: t('claim.withdraw'),
      onClick: withdraw,
      children: appConfigs.claim.withdrawReasons.map((withdrawReason) => ({
        ...withdrawReason,
        key: withdrawReason.value
      }))
    });
  }

  if (claim.permissions.destroy) {
    items.push({ key: 'destroy', label: t('globals.delete'), danger: true, onClick: destroy });
  }

  if (items.length === 0) {
    return null;
  }

  return <PageContextMenu items={items} />;
};

ClaimContextMenu.propTypes = {
  claim: PropTypes.object.isRequired
};

export default ClaimContextMenu;
