import { useEffect, useRef } from 'react';
import { Form, Modal } from 'antd';
import { useMutation } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router';
import { addErrorsFromAPIInForm, t } from '@gowgates/utils';
import { createTaskStructure } from '../../../api/endpoints';
import TaskStructureForm from '../TaskStructure/Form';
import ModalFormFooter from '../../../components/ModalFormFooter';
import { TaskStructure } from '../../../types';

type NewTaskStructureModalProps = {
  isOpen?: false;
  setOpen: (o: boolean) => void;
  entity: string;
};
const NewTaskStructureModal = ({ isOpen = false, setOpen, entity }: NewTaskStructureModalProps) => {
  const journeyId = parseInt(useParams<{ journeyId: string }>().journeyId || '', 10);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isOpen) {
      inputRef?.current?.focus();
      setTimeout(() => form.resetFields(), 100);
    }
  }, [isOpen]);

  const closeModal = () => {
    form.resetFields();
    setOpen(false);
  };

  const { isPending: isLoading, mutate } = useMutation({
    mutationFn: (values: TaskStructure) => createTaskStructure(journeyId, values),
    onError: (error) => addErrorsFromAPIInForm({ error, form }),
    onSuccess: (data) => {
      closeModal();
      navigate(`${data.id}`);
    }
  });

  return (
    <Modal title={t('taskStructure.new')} open={isOpen} onCancel={closeModal} footer={null}>
      <Form
        form={form}
        layout="vertical"
        onFinish={mutate}
        initialValues={{ entity }}
        disabled={isLoading}
      >
        <TaskStructureForm ref={inputRef} />

        <ModalFormFooter closeModal={closeModal} isLoading={isLoading} />
      </Form>
    </Modal>
  );
};

export default NewTaskStructureModal;
