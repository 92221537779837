import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Form, Modal, Input } from 'antd';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router';
import { FormItem } from '@gowgates/dynamic-fields';
import { addErrorsFromAPIInForm, t } from '@gowgates/utils';
import { createJourney } from '../../api/endpoints';
import ModalFormFooter from '../../components/ModalFormFooter';

const NewJourneyModal = ({ isOpen = false, setOpen }) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const inputRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      inputRef?.current?.focus();
    }
  }, [isOpen]);

  const closeModal = () => {
    form.resetFields();
    setOpen(false);
  };

  const { isPending: isLoading, mutate } = useMutation({
    mutationFn: (values) => createJourney(values),
    onError: (error) => addErrorsFromAPIInForm({ error, form }),
    onSuccess: (data) => {
      closeModal();
      navigate(`${data.id}`);
    }
  });

  return (
    <Modal title={t('journey.new')} open={isOpen} onCancel={closeModal} footer={null}>
      <Form form={form} layout="vertical" onFinish={mutate} disabled={isLoading}>
        <FormItem name="name" model="journey">
          <Input ref={inputRef} />
        </FormItem>

        <ModalFormFooter closeModal={closeModal} isLoading={isLoading} />
      </Form>
    </Modal>
  );
};

NewJourneyModal.propTypes = {
  isOpen: PropTypes.bool,
  setOpen: PropTypes.func.isRequired
};

export default NewJourneyModal;
