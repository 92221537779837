import { Tooltip, Typography } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { t } from '@gowgates/utils';

type DirectionIconProps = {
  direction: string;
};

const DirectionIcon = ({ direction }: DirectionIconProps) => {
  if (direction === 'incoming') {
    return (
      <Tooltip title={t('note.directions.incoming')}>
        <Typography.Text type="success">
          <LeftOutlined />
        </Typography.Text>
      </Tooltip>
    );
  }

  if (direction === 'outgoing') {
    return (
      <Tooltip title={t('note.directions.outgoing')}>
        <Typography.Text type="danger">
          <RightOutlined />
        </Typography.Text>
      </Tooltip>
    );
  }

  return null;
};

export default DirectionIcon;
