import Icon from '@ant-design/icons';
import React, { forwardRef } from 'react';
import { PrioritySvg } from './svgs/Priority';
import { VulnerabilitySvg } from './svgs/Vulnerability';
import { AtSvg } from './svgs/At';
import { HandPointer } from './svgs/HandPointer';

// forwardRef needed to avoid warning: https://github.com/ant-design/ant-design/issues/41497#issuecomment-1486101923
export const PriorityIcon = forwardRef((props, ref) => (
  <Icon component={PrioritySvg} {...props} ref={ref} />
));
export const VulnerabilityIcon = forwardRef((props, ref) => (
  <Icon component={VulnerabilitySvg} {...props} ref={ref} />
));
export const AtIcon = forwardRef((props, ref) => <Icon component={AtSvg} {...props} ref={ref} />);
export const HandPointerIcon = forwardRef((props, ref) => (
  <Icon component={HandPointer} {...props} ref={ref} />
));

export default {};
