import React from 'react';
import PropTypes from 'prop-types';
import { Form, Modal } from 'antd';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router';
import { addErrorsFromAPIInForm, t } from '@gowgates/utils';
import { createTaskTrigger } from '../../../api/endpoints';
import ModalFormFooter from '../../../components/ModalFormFooter';
import TaskTriggerForm from './Form';

const TaskTriggerModal = ({ isOpen = false, setOpen, event, taskStructure }) => {
  const journeyId = Number(useParams().journeyId);
  const taskId = Number(useParams().taskId);
  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  const closeModal = () => {
    form.resetFields();
    setOpen(false);
  };

  const { isPending: isLoading, mutate } = useMutation({
    mutationFn: (values) => createTaskTrigger(journeyId, taskId, values),
    onError: (error) => addErrorsFromAPIInForm({ error, form }),
    onSuccess: (data) => {
      queryClient.setQueryData(['taskStructure', taskId], data);
      closeModal();
    }
  });

  return (
    <Modal title={t(`taskTrigger.add.${event}`)} open={isOpen} onCancel={closeModal} footer={null}>
      <Form
        form={form}
        layout="vertical"
        onFinish={mutate}
        initialValues={{ event }}
        disabled={isLoading}
      >
        <TaskTriggerForm form={form} taskStructure={taskStructure} />

        <ModalFormFooter closeModal={closeModal} isLoading={isLoading} />
      </Form>
    </Modal>
  );
};

TaskTriggerModal.propTypes = {
  isOpen: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
  event: PropTypes.string.isRequired,
  taskStructure: PropTypes.object.isRequired
};

export default TaskTriggerModal;
