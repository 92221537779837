import { useQuery } from '@tanstack/react-query';

import { client, extractData } from '@gowgates/api-client';

import { SimpleUser } from '../../types';

const getMe = () =>
  client()
    .get('/users/me')
    .then(extractData<SimpleUser>);

export const userMeQueryKey = ['users', 'me'];

export const useMeQuery = () => {
  const { data, ...query } = useQuery({
    queryKey: userMeQueryKey,
    queryFn: getMe,
    staleTime: 120 * 1000 // refetch every 2 minutes
  });

  return { user: data, ...query };
};
