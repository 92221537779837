import PageHeader from './PageHeader';
import PageContent from './PageContent';
import PageContentWithSummary from './PageContentWithSummary';

type PageProps = {
  title?: string;
  overlap?: boolean;
  actions?: React.ReactNode;
  backUrl?: string;
  children: React.ReactNode;
  contextMenu?: React.ReactNode;
  nav?: React.ReactNode;
  summary?: React.ReactNode;
};

const Page = ({
  title = '',
  overlap = false,
  actions,
  backUrl,
  contextMenu,
  nav,
  summary,
  children
}: PageProps) => (
  <section>
    <PageHeader title={title} backUrl={backUrl} contextMenu={contextMenu}>
      {actions}
    </PageHeader>

    {summary ? (
      <PageContentWithSummary nav={nav} summary={summary}>
        {children}
      </PageContentWithSummary>
    ) : (
      <PageContent overlap={overlap}>{children}</PageContent>
    )}
  </section>
);

export default Page;
