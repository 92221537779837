import { Button, Space } from 'antd';
import { t } from '@gowgates/utils';
import ModalFooter from './ModalFooter';

type ModalFormFooterProps = {
  isLoading?: boolean;
  disabled?: boolean;
  closeModal: () => void;
};

const ModalFormFooter = ({
  closeModal,
  isLoading = false,
  disabled = false
}: ModalFormFooterProps) => (
  <ModalFooter>
    <Space>
      <Button onClick={closeModal}>{t('globals.cancel')}</Button>
      <Button type="primary" htmlType="submit" loading={isLoading} disabled={disabled}>
        {t('globals.save')}
      </Button>
    </Space>
  </ModalFooter>
);

export default ModalFormFooter;
