import { Button, ConfigProvider, GetProps, theme, ThemeConfig } from 'antd';
import { capitalCase } from 'change-case';

import { ButtonActionBase, ButtonActionColorAntThemeProperty } from '../../types';

type ButtonProps = GetProps<typeof Button>;
type ButtonUIProps = Omit<ButtonProps, 'type' | 'chidlren'> &
  Pick<ButtonActionBase, 'buttonType' | 'label'>;

export const ActionButtonAtom = ({ label, buttonType, ...buttonProps }: ButtonUIProps) => {
  const { token } = theme.useToken();

  if (buttonType === 'secondary') {
    return <Button {...buttonProps}>{label}</Button>;
  }

  const colorPrimaryKey = `color${capitalCase(buttonType)}` as ButtonActionColorAntThemeProperty;
  const buttonColor = token[colorPrimaryKey];
  const customTheme: ThemeConfig = {
    token: {
      colorPrimary: buttonColor || token.colorPrimary
    }
  };

  return (
    <ConfigProvider theme={customTheme}>
      <Button type="primary" {...buttonProps}>
        {label}
      </Button>
    </ConfigProvider>
  );
};
