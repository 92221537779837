import React from 'react';
import PropTypes from 'prop-types';
import { App } from 'antd';
import { useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router';
import { ExtendedDangerPopconfirm } from '@gowgates/dynamic-fields';
import { deleteReportStructure } from '../../../api/endpoints';

const DeleteReportStructure = ({ reportStructure, children, onSuccessfulDelete = () => {} }) => {
  const { message } = App.useApp();
  const structureId = Number(useParams().structureId);
  const queryClient = useQueryClient();

  const onSuccess = () => {
    queryClient.invalidateQueries({ queryKey: ['claimStructure', structureId] });
    message.success('Report deleted');

    onSuccessfulDelete();
  };

  return (
    <div className="d-flex justify-content-end">
      <ExtendedDangerPopconfirm
        deleteFn={() => deleteReportStructure(structureId, reportStructure.id)}
        invalidateQueries={['reportStructures', structureId]}
        permission={reportStructure.id}
        onSuccess={onSuccess}
        tooltip={false}
      >
        {children}
      </ExtendedDangerPopconfirm>
    </div>
  );
};

DeleteReportStructure.propTypes = {
  reportStructure: PropTypes.object.isRequired,
  children: PropTypes.node,
  onSuccessfulDelete: PropTypes.func
};

export default DeleteReportStructure;
