import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router';
import { t } from '@gowgates/utils';
import ButtonActionCol from './ButtonActionCol';

const ButtonActionDescriptions = ({ taskTrigger, taskStructure, title }) => {
  const journeyId = Number(useParams().journeyId);
  const queryClient = useQueryClient();
  const taskStructures = queryClient.getQueryData(['taskStructures', journeyId]);

  const columns = [
    {
      dataIndex: 'type',
      render: (text) => t(`buttonAction.types.${text}`)
    },
    {
      render: (_text, record) => (
        <ButtonActionCol
          buttonAction={record}
          taskStructure={taskStructure}
          taskStructures={taskStructures}
        />
      )
    }
  ];

  return (
    <>
      <h4>{title || t('activerecord.models.buttonAction.other')}</h4>

      <Table
        dataSource={taskTrigger.buttonActions}
        rowKey={(record) => record.id}
        pagination={false}
        className="box-table"
        showHeader={false}
        columns={columns}
      />
    </>
  );
};

ButtonActionDescriptions.propTypes = {
  taskTrigger: PropTypes.object.isRequired,
  taskStructure: PropTypes.object.isRequired,
  title: PropTypes.string
};

export default ButtonActionDescriptions;
