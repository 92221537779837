import PropTypes from 'prop-types';
import { Table } from 'antd';
import { camelCase } from 'change-case';
import { t, EMPTY_TEXT, formatDate } from '@gowgates/utils';
import { useTable } from '@gowgates/core';
import useLegacyTableSearch from '../../../hooks/useLegacyTableSearch';
import useAppConfigs from '../../../hooks/useAppConfigs';
import { convertToFilter } from '../../../utils/tables';
import ItemStatusTag from '../../claim/components/Items/StatusTag';

const ClaimsTable = ({ claims = [], loading, pagination, sorter, filters }) => {
  const { appConfigs } = useAppConfigs();

  const useEllipsis = true;

  const { handleTableChange } = useTable();
  const { columnProps } = useLegacyTableSearch({ useEllipsis, columnWidth: 150 });

  const columns = [
    { ...columnProps('claimId', 'payment', { sorter }) },
    {
      ...columnProps('status', 'item', {
        sorter,
        filters,
        filterOptions: convertToFilter(appConfigs.item.statuses)
      }),
      render: (_text, record) => <ItemStatusTag item={record} />
    },
    { ...columnProps('clientName', 'payment', { sorter, filters, textFilter: true }) },
    { ...columnProps('claimantName', 'payment', { sorter, filters, textFilter: true }) },
    { ...columnProps('bsb', 'payment') },
    { ...columnProps('accountNumber', 'payment') },
    { ...columnProps('swift', 'payment') },
    { ...columnProps('iban', 'payment') },
    {
      ...columnProps('paymentAmount', 'item', {
        title: t('activerecord.attributes.item.amount'),
        sorter,
        type: 'money'
      })
    },
    {
      ...columnProps('paidAt', 'item', {
        sorter,
        type: 'date'
      })
    }
  ];

  const structureNames = appConfigs.taskStructure.itemTaskNames.map(
    (taskStructure) => taskStructure.value
  );

  [...new Set(structureNames)].forEach((structureName) => {
    columns.push({
      ...columnProps(structureName, 'item', { title: structureName }),
      render: (_text, record) => {
        const value = record.history[camelCase(structureName)];

        return value ? formatDate(value, 'minute') : EMPTY_TEXT;
      }
    });
  });

  return (
    <Table
      dataSource={claims}
      rowKey={(record) => record.id}
      pagination={pagination}
      loading={loading}
      onChange={handleTableChange}
      scroll={{
        x: 2500
      }}
      columns={columns}
    />
  );
};

ClaimsTable.propTypes = {
  claims: PropTypes.array,
  loading: PropTypes.bool.isRequired,
  pagination: PropTypes.object.isRequired,
  sorter: PropTypes.object.isRequired,
  filters: PropTypes.object.isRequired
};

export default ClaimsTable;
