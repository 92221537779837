import { DynamicFormItem } from '@gowgates/dynamic-fields';

import useFileConfig from '../../../hooks/useFileConfig';

type PolicyDetailsFormProps = {
  nested: boolean;
};

const PolicyDetailsForm = ({ nested }: PolicyDetailsFormProps) => {
  const { coverStructure } = useFileConfig();
  const namespace = nested ? ['cover', 'data'] : ['data'];

  return coverStructure?.map((field) => (
    <DynamicFormItem key={field.name} field={field} namespace={namespace} />
  ));
};

export default PolicyDetailsForm;
