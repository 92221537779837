type ModalFooterProps = {
  children: React.ReactNode;
  spaceBetween?: boolean;
};

const ModalFooter = ({ children, spaceBetween = false }: ModalFooterProps) => (
  <footer className={`d-flex ${spaceBetween ? 'justify-content-between' : 'justify-content-end'}`}>
    {children}
  </footer>
);

export default ModalFooter;
