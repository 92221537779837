import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import forIn from 'lodash.forin';
import { camelCase, snakeCase } from 'change-case';
import qs from 'query-string';
import { TableProps } from 'antd';
import {
  Sorts,
  UseTableProps,
  columnKey,
  columnSortOrder,
  columnTitle,
  columnWithLinkRender
} from './utils';

export function useTable<Type extends object>({
  columns,
  model,
  rowUrlPattern,
  shouldAddUrlForRecord = () => true,
  frontendSort = false
}: UseTableProps<Type> = {}) {
  const search = qs.parse(useLocation().search);
  const navigate = useNavigate();

  const [sorter, setSorter] = useState<Sorts<Type>>({});

  const updatedSorterFromParams = useCallback(() => {
    const result: Sorts<Type> = {};

    if (typeof search.sort !== 'string') {
      setSorter(result);
      return;
    }

    if (search.sort.indexOf('-') === 0) {
      result.field = camelCase(search.sort.slice(1, search.sort.length));
      result.order = 'descend';
    } else {
      result.field = camelCase(search.sort);
      result.order = 'ascend';
    }

    setSorter(result);
  }, [search.sort]);

  useEffect(() => {
    const result: Sorts<Type> = {};

    if (typeof search.sort !== 'string') {
      setSorter(result);
      return;
    }

    if (search.sort.indexOf('-') === 0) {
      result.field = camelCase(search.sort.slice(1, search.sort.length));
      result.order = 'descend';
    } else {
      result.field = camelCase(search.sort);
      result.order = 'ascend';
    }

    setSorter(result);
  }, [search.sort]);

  const processedColumns = useMemo(
    () =>
      columns?.map((c, index) => {
        const column = {
          ...(rowUrlPattern ? columnWithLinkRender(c, rowUrlPattern, shouldAddUrlForRecord) : c),
          key: columnKey(c, index),
          title: columnTitle(c, model),
          sortOrder: columnSortOrder(c, sorter)
        };

        if (frontendSort) delete column.sortOrder;

        return column;
      }),
    [columns, sorter, model, rowUrlPattern, shouldAddUrlForRecord, frontendSort]
  );

  const handleTableChange: TableProps<Type>['onChange'] = (pagination, filters, sorter, extra) => {
    const newParams: {
      [key: string]: string | unknown[] | null;
    } = { ...search };

    if (extra.action === 'paginate') {
      if (pagination.current !== 1) {
        newParams.page = `${pagination.current}`;
      } else {
        delete newParams['page'];
      }

      if (pagination.pageSize !== pagination.defaultPageSize) {
        newParams.per_page = `${pagination.pageSize}`;
      } else {
        delete newParams['per_page'];
      }
    } else if (extra.action === 'sort' && !Array.isArray(sorter)) {
      delete newParams['page'];

      if (!sorter.order) {
        delete newParams['sort'];
      } else {
        newParams.sort = `${sorter.order === 'descend' ? '-' : ''}${snakeCase(
          `${sorter.field || sorter.columnKey}`
        )}`;
      }
    } else if (extra.action === 'filter') {
      delete newParams['page'];

      forIn(filters, (value, key) => {
        if (value) {
          newParams[snakeCase(key)] = Array.isArray(value) ? value : `${value}`;
        } else {
          delete newParams[snakeCase(key)];
        }
      });
    }

    navigate({ search: qs.stringify(newParams) });
  };

  return {
    search,
    sorter,
    handleTableChange,
    updatedSorterFromParams,
    columns: processedColumns
  };
}
