import { useEffect, useRef, useState } from 'react';
import { Form, Button, App, InputRef } from 'antd';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { addErrorsFromAPIInForm, t } from '@gowgates/utils';

import { usePageTitle } from '../../../hooks';
import { Loader } from '../../../components';
import { AuthContainer } from '../Container';
import { AuthForm } from '../components/AuthForm';
import { PasswordInput } from '../components/PasswordInput';
import { getPasswordToken, resetPassword } from '../api/endpoints';
import { LoginLink } from '../components/LoginLink';

export const ResetPassword = () => {
  const { message } = App.useApp();
  const inputRef = useRef<InputRef>(null);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [isTokenInvalid, setTokenInvalid] = useState(false);
  const [form] = Form.useForm();
  usePageTitle(t('user.setPassword'));

  const passwordToken = searchParams.get('reset_password_token');

  useEffect(() => {
    inputRef?.current?.focus();
  }, [inputRef]);

  const { isPending, mutate } = useMutation({
    mutationFn: (values: { password: string; passwordConfirmation: string }) =>
      resetPassword({ ...values, resetPasswordToken: passwordToken }),
    onError: (error) =>
      addErrorsFromAPIInForm({
        error,
        form
      }),
    onSuccess: () => {
      message.success(t('devise.passwords.updated'));
      navigate('/login');
    }
  });

  const { isLoading: isGettingToken, isError } = useQuery({
    queryKey: ['passwordToken'],
    queryFn: () => {
      if (!passwordToken) return;

      return getPasswordToken(passwordToken);
    },
    throwOnError: false
  });

  useEffect(() => {
    if (isError) setTokenInvalid(true);
  }, [isError]);

  if (isGettingToken) {
    return <Loader />;
  }

  if (isTokenInvalid) {
    return (
      <AuthContainer
        title={t('user.resetPassword')}
        errorMessage={t('devise.passwords.invalidToken')}
        footer={<LoginLink />}
      />
    );
  }

  return (
    <AuthContainer title={t('user.setPassword')} footer={<LoginLink />}>
      <AuthForm
        form={form}
        onFinish={mutate}
        disabled={isPending}
        initialValues={{ resetPasswordToken: passwordToken }}
      >
        <PasswordInput />
        <PasswordInput confirmation />

        <Button type="primary" htmlType="submit" className="w-100" loading={isPending}>
          {t('user.setMyPassword')}
        </Button>
      </AuthForm>
    </AuthContainer>
  );
};
