import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Table, Typography } from 'antd';
import { t, parseDate, formatPrice } from '@gowgates/utils';
import { DateFormatter, DynamicFormatter } from '@gowgates/dynamic-fields';
import useAppConfigs from '../../../../hooks/useAppConfigs';
import { convertToFilter } from '../../../../utils/tables';
import ItemStatusTag from './StatusTag';

const { Column } = Table;

const ItemsTable = ({ claim, sectionStructure, section, editItem, showItem }) => {
  const descriptionColumn = sectionStructure.items.find((item) => item.name === 'description');
  const spentAtColumn = sectionStructure.items.find((item) => item.name === 'spent_at');
  const amountColumn = sectionStructure.items.find((item) => item.name === 'amount');
  const summaryEmptyCells = (descriptionColumn ? 1 : 0) + (spentAtColumn ? 1 : 0);
  const { appConfigs } = useAppConfigs();

  const totalsRow = useCallback(() => {
    if (section.items.length === 0) {
      return null;
    }

    const emptyCells = [...Array(summaryEmptyCells)].map((_val, index) => (
      <Table.Summary.Cell index={index} key={index} />
    ));

    return (
      <Table.Summary.Row>
        {emptyCells}
        <Table.Summary.Cell index={summaryEmptyCells}>{t('item.totals')}</Table.Summary.Cell>
        {amountColumn && (
          <Table.Summary.Cell index={summaryEmptyCells + 1}>
            {formatPrice(section.claimedAmount)}
          </Table.Summary.Cell>
        )}
        <Table.Summary.Cell index={summaryEmptyCells + 2}>
          {formatPrice(section.paymentAmount)}
        </Table.Summary.Cell>
        {claim.status !== 'draft' && <Table.Summary.Cell index={summaryEmptyCells + 3} />}
      </Table.Summary.Row>
    );
  }, [section.items]);

  const selectRow = (record) => {
    if (editItem && record.status === 'draft') {
      editItem(record, sectionStructure);
    } else if (showItem) {
      showItem(record, sectionStructure);
    }
  };

  return (
    <Table
      dataSource={section.items}
      rowKey={(record) => record.id}
      pagination={false}
      className="box-table"
      summary={totalsRow}
    >
      <Column
        title={t('activerecord.attributes.item.id')}
        key="id"
        className="col-id"
        sorter={(a, b) => a.id - b.id}
        render={(record) => (
          <Typography.Link onClick={() => selectRow(record)} className="col-clickable">
            {record.id}
          </Typography.Link>
        )}
      />

      {descriptionColumn && (
        <Column
          title={descriptionColumn.label}
          key="name"
          sorter={(a, b) => (a.data.name < b.data.name ? -1 : 1)}
          ellipsis
          render={(record) => (
            <Typography.Link onClick={() => selectRow(record)} className="col-clickable" ellipsis>
              <DynamicFormatter data={record.data} field={descriptionColumn} />
            </Typography.Link>
          )}
        />
      )}

      {spentAtColumn && (
        <Column
          title={spentAtColumn.label}
          key="spentAt"
          className="col-date"
          sorter={(a, b) => (parseDate(a.data.spentAt) < parseDate(b.data.spentAt) ? -1 : 1)}
          render={(record) => (
            <Typography.Link onClick={() => selectRow(record)} className="col-clickable">
              <DateFormatter value={record.data.spentAt} />
            </Typography.Link>
          )}
        />
      )}

      {amountColumn && (
        <Column
          title={t('activerecord.attributes.item.claimedAmount')}
          key="amount"
          className="col-amount"
          sorter={(a, b) => a.data.amount - b.data.amount}
          render={(record) => (
            <Typography.Link onClick={() => selectRow(record)} className="col-clickable">
              {formatPrice(record.data.amount, record.data.amountCurrency)}
            </Typography.Link>
          )}
        />
      )}

      <Column
        title={t('activerecord.attributes.item.paymentAmount')}
        key="paymentAmount"
        className="col-amount"
        sorter={(a, b) => a.paymentAmount - b.paymentAmount}
        render={(record) => (
          <Typography.Link onClick={() => selectRow(record)} className="col-clickable">
            {formatPrice(record.paymentAmount, record.paymentAmountCurrency)}
          </Typography.Link>
        )}
      />

      {claim.status !== 'draft' && (
        <Column
          title={t('activerecord.attributes.item.status')}
          key="status"
          className="text-right col-status"
          sorter={(a, b) => (a.status < b.status ? -1 : 1)}
          filters={convertToFilter(appConfigs.item.statuses)}
          onFilter={(value, record) => record.status === value}
          render={(record) => (
            <Typography.Link onClick={() => selectRow(record)} className="col-clickable">
              <ItemStatusTag item={record} />
            </Typography.Link>
          )}
        />
      )}
    </Table>
  );
};

ItemsTable.propTypes = {
  claim: PropTypes.object.isRequired,
  sectionStructure: PropTypes.object.isRequired,
  section: PropTypes.object.isRequired,
  editItem: PropTypes.func,
  showItem: PropTypes.func
};

export default ItemsTable;
