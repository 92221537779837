import React from 'react';
import PropTypes from 'prop-types';
import { Empty } from 'antd';
import { camelCase } from 'change-case';
import { t } from '@gowgates/utils';

const countSections = (sections, claim) =>
  sections.filter((sectionStructure) => !!claim.sections[camelCase(sectionStructure.slug)]).length;

const Sections = ({ claim, children }) => {
  const sectionsCount = countSections(claim.structure.sections, claim);

  if (sectionsCount < 1) {
    return (
      <Empty
        className="empty-sections"
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={t('section.empty')}
      />
    );
  }

  return (
    <>
      {claim.structure.sections.map(
        (sectionStructure) =>
          !!claim.sections[camelCase(sectionStructure.slug)] && (
            <React.Fragment key={sectionStructure.slug}>
              {typeof children === 'function'
                ? children(sectionStructure, claim.sections[camelCase(sectionStructure.slug)])
                : children}
            </React.Fragment>
          )
      )}
    </>
  );
};

Sections.propTypes = {
  claim: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func])
};

export default Sections;
