import { useQuery } from '@tanstack/react-query';

import { getFileConfig } from '../api/endpoints';

const useFileConfig = () => {
  const { data, ...query } = useQuery({
    queryKey: ['fileConfig'],
    queryFn: () => getFileConfig(),
    placeholderData: { claimant: [], client: [], cover: [] },
    staleTime: Infinity
  });

  return {
    ...query,
    claimantStructure: data?.claimant,
    clientStructure: data?.client,
    coverStructure: data?.cover
  };
};

export default useFileConfig;
