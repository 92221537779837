import { useEffect } from 'react';
import { Form, GetProps } from 'antd';
import { QuillOptions } from 'quill';
import { useQuill } from 'react-quilljs';

type AntFormItemProps = GetProps<typeof Form.Item>;
type Props = QuillOptions & {
  formItemId: AntFormItemProps['id'];
  onChange?: (searchText: string) => void;
};

export const useQuillFormItem = ({ formItemId, onChange, ...quillOptions }: Props) => {
  const form = Form.useFormInstance();

  const bindings = {
    shortKeyEnter: {
      key: 'Enter',
      shortKey: true,
      handler: () => {
        if (form) form.submit();
      }
    },
    ctrlKeyEnter: {
      key: 'Enter',
      ctrlKey: true,
      handler: () => {
        if (form) form.submit();
      }
    }
  };

  const modules = {
    keyboard: {
      bindings
    },
    ...(quillOptions.modules || {})
  };
  const quillResults = useQuill({ ...quillOptions, modules });
  const quill = quillResults.quill;

  useEffect(() => {
    if (quill && form && formItemId) {
      quill.clipboard.dangerouslyPasteHTML(form.getFieldValue(formItemId));
    }
  }, [quill, form, formItemId]);

  useEffect(() => {
    if (quill && form && formItemId) {
      quill.once('text-change', () => {
        /* using getSemanticHTML to have correct html elements -- for example unorder list using ul instead of ol */
        const htmlText = quill.getSemanticHTML();

        // onChange is passed down by antd
        if (onChange) onChange(htmlText);
      });
    }
  }, [quill, form, formItemId, onChange]);

  return { form, ...quillResults };
};
