import { DataType, t } from '@gowgates/utils';
import { MessageInstance } from 'antd/es/message/interface';

export const showSavedMessage = (message: MessageInstance) => {
  message.success(`${t('globals.saved')}!`);
};

export const showBaseErrors = (data: DataType, message: MessageInstance) => {
  message.error(data.errors?.base?.join(', '));
};

export default {
  showSavedMessage,
  showBaseErrors
};
