import React from 'react';

import InnerBox from './InnerBox';

type BoxProps = {
  title?: string;
  className?: string;
  children: React.ReactNode;
};

const Box = ({ title, children, className = '' }: BoxProps) => (
  <InnerBox title={title} className={`box ${className}`}>
    {children}
  </InnerBox>
);

export default Box;
