type PageContentProps = {
  children: React.ReactNode;
  overlap?: boolean;
};

const PageContent = ({ overlap = false, children }: PageContentProps) => (
  <div className={`container ${overlap ? 'overlap-header' : ''}`}>{children}</div>
);

export default PageContent;
