import React, { useEffect } from 'react';
import { App, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { t } from '@gowgates/utils';
import { setPageTitle } from '@gowgates/core';
import { deletePermission, getPermission } from '../../api/endpoints';
import Page from '../../components/Page';
import { showBaseErrors } from '../../utils/messages';
import FullLoader from '../../components/FullLoader';
import PageContextMenu from '../../components/PageContextMenu';
import PermissionNav from './PermissionNav';

const Permission = () => {
  const { message } = App.useApp();
  const permissionId = Number(useParams().permissionId);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname === `/permissions/${permissionId}`) {
      navigate('permissions', { replace: true });
    }
  }, [pathname]);

  const { mutate: mudateDestroy } = useMutation({
    mutationFn: () => deletePermission(permissionId),
    onError: (error) => {
      showBaseErrors(error.response.data, message);
    },
    onSuccess: () => {
      message.success(`${t('globals.deleted')}!`);
      queryClient.invalidateQueries({ queryKey: ['permissions'] }).then(() => {
        navigate('/permissions');
      });
    }
  });

  const { isLoading, data: permission } = useQuery({
    queryKey: ['permission', permissionId],
    queryFn: () => getPermission(permissionId)
  });

  useEffect(() => {
    if (!permission) return;

    setPageTitle(`${permission.name} | ${t('activerecord.models.permission.other')}`);
  }, [permission]);

  if (isLoading) {
    return <FullLoader />;
  }

  const destroy = () => {
    Modal.confirm({
      title: t('permission.confirmDestroy'),
      icon: <ExclamationCircleOutlined />,
      okText: t('globals.yesNo.true'),
      okType: 'danger',
      cancelText: t('globals.yesNo.false'),
      onOk() {
        mudateDestroy();
      }
    });
  };

  const contextMenuItems = [];
  if (permission.permissions.destroy) {
    contextMenuItems.push({ key: 'delete', label: t('globals.delete'), onClick: destroy });
  }

  return (
    <Page
      title={permission.name}
      backUrl="/permissions"
      contextMenu={<PageContextMenu items={contextMenuItems} />}
      nav={<PermissionNav />}
      summary={<></>}
    >
      <Outlet />
    </Page>
  );
};

export default Permission;
