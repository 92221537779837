import { FormItem } from '@gowgates/dynamic-fields';
import { Input } from 'antd';
import { EmailTemplateEditor } from '../../components/EmailTemplateEditor';

export const EmailTemplateFormContent = () => (
  <>
    <FormItem name="name" model="emailTemplate" required>
      <Input />
    </FormItem>

    <FormItem name="content" model="emailTemplate" required>
      <EmailTemplateEditor toolbarId="toolbar-content" />
    </FormItem>
  </>
);
