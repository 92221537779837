import { Dropdown, Button, Space, Typography, MenuProps, DropdownProps } from 'antd';

import { AnyObject, t } from '@gowgates/utils';
import { User, UserAvatar, AvatarAndName } from '@gowgates/core';

import useResourceUsers from '../../../hooks/useResourceUsers';

type ReassignBtnProps = {
  currentAssignee?: User;
  placement?: DropdownProps['placement'];
  onChange?: (key: string) => void;
  label?: string;
  dropdown?: string;
  showRole?: boolean;
  filters?: AnyObject;
};

const ReassignBtn = ({
  currentAssignee,
  placement = 'bottomLeft',
  onChange = () => {},
  label,
  dropdown = 'link',
  showRole = false,
  filters = {}
}: ReassignBtnProps) => {
  const { users } = useResourceUsers({ ...filters, status: 'active' });

  const onClick: MenuProps['onClick'] = (event) => {
    onChange(event.key);
  };

  const items = [
    {
      key: '-1',
      label: (
        <Space>
          <UserAvatar user={{ firstName: '?', lastName: '' }} />
          <Typography.Text type="secondary" italic>
            {t('claim.unassigned')}
          </Typography.Text>
        </Space>
      )
    },
    ...(users || []).map((user) => ({
      key: `${user.id}`,
      label: <AvatarAndName user={user} showRole={showRole} />
    }))
  ];

  let dropDownContainer;

  if (dropdown === 'button') {
    dropDownContainer = <Button>{label || t('globals.change')}</Button>;
  } else {
    dropDownContainer = <span role="link">{label || t('globals.change')}</span>;
  }

  const menu = {
    items,
    onClick,
    selectable: true,
    defaultSelectedKeys: [`${currentAssignee?.id}` || '-1']
  };

  return (
    <Dropdown menu={menu} trigger={['click']} placement={placement} className="reassign-btn">
      <Typography.Link>{dropDownContainer}</Typography.Link>
    </Dropdown>
  );
};

export default ReassignBtn;
