import PropTypes from 'prop-types';
import { Table } from 'antd';
import { t } from '@gowgates/utils';
import { useTable } from '@gowgates/core';
import useLegacyTableSearch from '../../hooks/useLegacyTableSearch';
import useAppConfigs from '../../hooks/useAppConfigs';
import { ClickableColumnContent } from './ClickableColumnContent';

const PaymentsTable = ({ payments = [], loading, pagination, filters, sorter, rowSelection }) => {
  const { columnProps } = useLegacyTableSearch();
  const { handleTableChange } = useTable();
  const { appConfigs } = useAppConfigs();

  const columns = [
    {
      ...columnProps('claimId', 'payment', {
        sorter,
        cellContainer: ClickableColumnContent
      })
    },
    {
      ...columnProps('clientName', 'payment', {
        sorter,
        filters,
        textFilter: true,
        cellContainer: ClickableColumnContent
      })
    },
    {
      ...columnProps('claimantName', 'payment', {
        sorter,
        filters,
        textFilter: true,
        cellContainer: ClickableColumnContent
      })
    },
    {
      ...columnProps('bsb', 'payment'),
      title: `${t(`activerecord.attributes.payment.bsb`)} / ${t(
        `activerecord.attributes.payment.swift`
      )}`,
      render: (text, record) => (
        <ClickableColumnContent record={record}>
          {record.bankAccountType === 'australia' ? record.bsb : record.swift}
        </ClickableColumnContent>
      )
    },
    {
      ...columnProps('accountNumber', 'payment'),
      title: `${t(`activerecord.attributes.payment.accountNumber`)} / ${t(
        `activerecord.attributes.payment.iban`
      )}`,
      render: (text, record) => (
        <ClickableColumnContent record={record}>
          {record.bankAccountType === 'australia' ? record.accountNumber : record.iban}
        </ClickableColumnContent>
      )
    },
    {
      ...columnProps('amount', 'payment', {
        sorter,
        type: 'money',
        cellContainer: ClickableColumnContent
      })
    },
    {
      ...columnProps('dueAt', 'payment', {
        sorter,
        filters,
        type: 'date',
        cellContainer: ClickableColumnContent
      })
    },
    {
      ...columnProps('taskName', 'payment', {
        sorter,
        filters,
        filterOptions: appConfigs.taskStructure.itemTaskNames,
        cellContainer: ClickableColumnContent
      })
    }
  ];

  return (
    <Table
      dataSource={payments}
      rowKey={(record) => record.id}
      pagination={pagination}
      loading={loading}
      onChange={handleTableChange}
      className="page-table"
      rowSelection={rowSelection}
      columns={columns}
    />
  );
};

export default PaymentsTable;

PaymentsTable.propTypes = {
  payments: PropTypes.array,
  loading: PropTypes.bool.isRequired,
  pagination: PropTypes.object.isRequired,
  filters: PropTypes.object.isRequired,
  sorter: PropTypes.object.isRequired,
  rowSelection: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]).isRequired
};
