import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Form, Modal, Input } from 'antd';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { FormItem } from '@gowgates/dynamic-fields';
import { addErrorsFromAPIInForm, t } from '@gowgates/utils';
import { createPermission } from '../../api/endpoints';
import ModalFormFooter from '../../components/ModalFormFooter';

const NewPermissionModal = ({ isOpen = false, setOpen }) => {
  const [form] = Form.useForm();
  const inputRef = useRef(null);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (isOpen) {
      inputRef?.current?.focus();
    }
  }, [isOpen]);

  const closeModal = () => {
    form.resetFields();
    setOpen(false);
  };

  const { isPending: isLoading, mutate } = useMutation({
    mutationFn: (values) => createPermission(values),
    onError: (error) => addErrorsFromAPIInForm({ error, form }),
    onSuccess: () => {
      closeModal();
      queryClient.invalidateQueries({ queryKey: ['permissions'] });
    }
  });

  return (
    <Modal title={t('permission.new')} open={isOpen} onCancel={closeModal} footer={null}>
      <Form form={form} layout="vertical" onFinish={mutate} disabled={isLoading}>
        <FormItem name="name" model="permission">
          <Input ref={inputRef} />
        </FormItem>

        <ModalFormFooter closeModal={closeModal} isLoading={isLoading} />
      </Form>
    </Modal>
  );
};

NewPermissionModal.propTypes = {
  isOpen: PropTypes.bool,
  setOpen: PropTypes.func.isRequired
};

export default NewPermissionModal;
