import set from 'lodash.set';
import { Upload, Button, Form } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { t } from '@gowgates/utils';
import { File } from '../File';

type AntButtonProps = React.ComponentProps<typeof Button>;
type FilePickerProps = {
  id?: string;
  accept?: string;
  value?: {
    url?: string;
    type?: string;
    name?: string;
  };
  disabled?: boolean;
  iconRenderType?: 'file' | 'url';
};

const FilePickerButton = (buttonPros: AntButtonProps) => (
  <Button icon={<UploadOutlined />} {...buttonPros}>
    {t('globals.attach')}
  </Button>
);

export const FilePicker = ({
  id,
  value,
  accept,
  disabled = false,
  iconRenderType = 'file'
}: FilePickerProps) => {
  const form = Form.useFormInstance();

  const onRemove = () => {
    form.setFieldsValue(set({}, (id || '').split('_'), null));
  };

  return (
    <>
      <div className={value ? 'd-none' : ''}>
        <Upload
          fileList={[]}
          beforeUpload={(file) => {
            // when received as composed name like `['data', 'attachment']` the id is `data_attachment`
            form.setFieldsValue(set({}, (id || '').split('_'), file));

            return false;
          }}
          accept={accept}
        >
          <FilePickerButton disabled={disabled} />
        </Upload>
      </div>

      <div className={value ? '' : 'd-none'}>
        <File
          url={value?.url}
          type={value?.type}
          name={value?.name}
          onRemove={onRemove}
          hasErrors={form.getFieldError(id).length > 0}
          disabled={disabled}
          iconRenderType={iconRenderType}
        />
      </div>
    </>
  );
};
