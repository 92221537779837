import { Link } from 'react-router-dom';
import { Row, Col, Space, Typography } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';

import { useTenant } from '../hooks/useTenant';

type PageHeaderProps = {
  title?: string;
  backUrl?: string;
  titleWidth?: number;
  contextMenu?: React.ReactNode;
  children?: React.ReactNode;
};

const PageHeader = ({
  title = '',
  backUrl = '',
  titleWidth = 12,
  contextMenu,
  children
}: PageHeaderProps) => {
  const { tenant } = useTenant();

  return (
    <header className="page-header" style={{ background: tenant?.bgColor }}>
      <div className="container">
        <Row>
          <Col span={titleWidth}>
            <Typography.Title className="page-title">
              <Space>
                {backUrl && (
                  <Link to={backUrl} className="back-btn">
                    <ArrowLeftOutlined />
                  </Link>
                )}
                <span>{title}</span>
                {contextMenu}
              </Space>
            </Typography.Title>
          </Col>

          <Col span={24 - titleWidth} className="d-flex justify-content-end">
            <Space>{children}</Space>
          </Col>
        </Row>
      </div>
    </header>
  );
};

export default PageHeader;
