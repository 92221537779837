import React from 'react';
import PropTypes from 'prop-types';
import { camelCase } from 'change-case';
import { Input } from 'antd';
import { FormItem, DynamicFormItem } from '@gowgates/dynamic-fields';

const SectionForm = ({ sectionStructure, namespaced = false }) => {
  const namespace = namespaced ? ['sections', camelCase(sectionStructure.slug)] : [];

  return (
    <section>
      <FormItem name={[...namespace, 'type']} hidden>
        <Input />
      </FormItem>

      {sectionStructure.fields.map((field) => (
        <DynamicFormItem key={field.name} field={field} namespace={[...namespace, 'data']} />
      ))}
    </section>
  );
};

SectionForm.propTypes = {
  sectionStructure: PropTypes.object.isRequired,
  namespaced: PropTypes.bool
};

export default SectionForm;
