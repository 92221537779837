import numeral, { Numeral } from 'numeral';
import { DEFAULT_CURRENCY, PRICE_FORMAT } from './constants';
import { t } from './I18n';

export const EMPTY_TEXT = '-';

export const displayText = (text?: string) => {
  if (!text) {
    return EMPTY_TEXT;
  }

  return text;
};

export const formatPrice = (value?: string | number | Numeral, currency = DEFAULT_CURRENCY) => {
  if (value === undefined || value === null) {
    return EMPTY_TEXT;
  }

  return `${currency || DEFAULT_CURRENCY} ${numeral(value).format(PRICE_FORMAT)}`;
};

export const formatBoolean = (value?: boolean) => {
  if (value === null || value === undefined) {
    return '-';
  }

  return t(`globals.yesNo.${value}`);
};

export const isHtmlContentEmpty = (htmlString?: string) => {
  if (!htmlString) return true;

  // remove all html tags
  const strippedString = htmlString.replace(/<[^>]*>/g, '');

  // remove blank spaces, break lines, tabs
  const cleanedString = strippedString.replace(/\s+/g, '');

  return !cleanedString;
};
