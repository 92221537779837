import sanitizeHtml from 'sanitize-html';

import { CustomComponent } from '@gowgates/utils';

type RichTextHtmlProps = Omit<React.HTMLAttributes<HTMLElement>, 'dangerouslySetInnerHTML'> & {
  htmlText?: string;
  noStyle?: boolean;
  Component?: CustomComponent;
  variant?: 'text' | 'otp-svg';
};

const richTextEditorSanitizer = (htmlText: string) =>
  sanitizeHtml(htmlText, {
    allowedTags: [
      'b',
      'i',
      'strong',
      'em',
      'u',
      'ul',
      'ol',
      'li',
      'br',
      'p',
      'div',
      'span',
      'pre',
      'blockquote',
      'code',
      'a',
      'img',
      'h1',
      'h2',
      'h3',
      'h4',
      'h5',
      'h6'
    ],
    allowedAttributes: {
      span: ['class'],
      p: ['class'],
      a: ['href', 'target', 'rel'],
      img: ['alt']
    }
  });

const otpSvgSanitizer = (htmlText: string) =>
  sanitizeHtml(htmlText, {
    allowedTags: ['svg', 'xml', 'rect'],
    allowedAttributes: false,
    parser: {
      lowerCaseTags: false,
      lowerCaseAttributeNames: false
    }
  });

export const RichTextHtml = ({
  htmlText,
  className = '',
  noStyle = false,
  Component = 'div',
  variant = 'text',
  ...props
}: RichTextHtmlProps) => {
  if (!htmlText) return null;

  if (variant === 'text') {
    return (
      <Component
        className={noStyle ? className : `${className} html-text`}
        {...props}
        // in last to make sure it is not possible to replace it, opening the possibility for security issues
        dangerouslySetInnerHTML={{ __html: richTextEditorSanitizer(htmlText) }}
      />
    );
  }

  if (variant === 'otp-svg') {
    return (
      <Component
        className={className}
        {...props}
        // in last to make sure it is not possible to replace it, opening the possibility for security issues
        dangerouslySetInnerHTML={{ __html: otpSvgSanitizer(htmlText) }}
      />
    );
  }
};
