import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Select } from 'antd';
import { FormItem } from '@gowgates/dynamic-fields';

const FieldExpression = ({ fields = [] }) => {
  const evalTypeUpdated = (prevValues, currentValues) =>
    prevValues.evalType !== currentValues.evalType;

  return (
    <Form.Item noStyle shouldUpdate={evalTypeUpdated}>
      {({ getFieldValue }) =>
        getFieldValue('evalType') === 'direct' ? (
          <FormItem name="fieldName" model="reportField">
            <Select options={fields} showSearch fieldNames={{ value: 'name', options: 'false' }} />
          </FormItem>
        ) : (
          <FormItem name="fieldExpression" model="reportField">
            <Input />
          </FormItem>
        )
      }
    </Form.Item>
  );
};

FieldExpression.propTypes = {
  fields: PropTypes.array
};

export default FieldExpression;
