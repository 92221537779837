import React from 'react';
import { Button, Form, Input, App } from 'antd';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { useParams } from 'react-router';
import { FormItem } from '@gowgates/dynamic-fields';
import { addErrorsFromAPIInForm, t } from '@gowgates/utils';
import { usePageTitle } from '@gowgates/core';
import { updateSectionStructure } from '../../../../api/endpoints';
import { showSavedMessage } from '../../../../utils/messages';

const StructureSectionDetails = () => {
  const { message } = App.useApp();
  const structureId = Number(useParams().structureId);
  const sectionId = Number(useParams().sectionId);
  const queryClient = useQueryClient();
  const sectionStructure = queryClient.getQueryData(['sectionStructure', sectionId]);
  usePageTitle(
    `${sectionStructure.name} - ${t('globals.details')} | ${t(
      'activerecord.models.claimStructure.other'
    )}`
  );

  const [form] = Form.useForm();

  const { isPending: isLoading, mutate } = useMutation({
    mutationFn: (values) => updateSectionStructure(structureId, sectionId, values),
    onError: (error) => addErrorsFromAPIInForm({ error, form }),
    onSuccess: (data) => {
      queryClient.setQueryData(['sectionStructure', sectionId], data);
      showSavedMessage(message);
    }
  });

  return (
    <Form
      form={form}
      onFinish={mutate}
      initialValues={sectionStructure}
      layout="vertical"
      disabled={isLoading}
    >
      <FormItem name="name" model="sectionStructure" required>
        <Input />
      </FormItem>

      <div className="d-flex justify-content-end">
        <Button type="primary" htmlType="submit" loading={isLoading}>
          {t('globals.save')}
        </Button>
      </div>
    </Form>
  );
};

export default StructureSectionDetails;
