import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { Link } from 'react-router-dom';
import { useTable } from '@gowgates/core';
import useLegacyTableSearch from '../../../hooks/useLegacyTableSearch';
import useAppConfigs from '../../../hooks/useAppConfigs';
import { convertToFilter } from '../../../utils/tables';

const ClaimsTable = ({ claims = [], loading, pagination, sorter, filters }) => {
  const { appConfigs } = useAppConfigs();

  const useEllipsis = true;
  const { handleTableChange } = useTable();
  const { columnProps } = useLegacyTableSearch({ useEllipsis, columnWidth: 150 });

  const columns = [
    { ...columnProps('id', 'claim'), render: (text) => <Link to={`/claims/${text}`}>{text}</Link> },
    {
      ...columnProps('status', 'claim', {
        sorter,
        filters,
        filterOptions: convertToFilter(appConfigs.claim.statuses),
        type: 'enum'
      })
    },
    {
      ...columnProps('liability', 'claim', {
        sorter,
        filters,
        filterOptions: convertToFilter(appConfigs.claim.liabilities),
        type: 'enum'
      })
    },
    { ...columnProps('clientName', 'payment', { sorter, filters, textFilter: true }) },
    { ...columnProps('claimantName', 'payment', { sorter, filters, textFilter: true }) },
    { ...columnProps('createdAt', 'claim', { type: 'datetime' }) },
    { ...columnProps('approvedAt', 'claim', { type: 'datetime' }) },
    { ...columnProps('approvedBy', 'claim') },
    { ...columnProps('declinedAt', 'claim', { type: 'datetime' }) },
    { ...columnProps('declinedBy', 'claim') },
    { ...columnProps('closedAt', 'claim', { type: 'datetime' }) },
    { ...columnProps('closedBy', 'claim') }
  ];

  return (
    <Table
      dataSource={claims}
      rowKey={(record) => record.id}
      pagination={pagination}
      loading={loading}
      onChange={handleTableChange}
      scroll={{
        x: 1800
      }}
      columns={columns}
    />
  );
};

ClaimsTable.propTypes = {
  claims: PropTypes.array,
  loading: PropTypes.bool.isRequired,
  pagination: PropTypes.object.isRequired,
  sorter: PropTypes.object.isRequired,
  filters: PropTypes.object.isRequired
};

export default ClaimsTable;
