import { UploadOutlined } from '@ant-design/icons';
import { Button, Upload, Form } from 'antd';

import DocumentUploader from './DocumentUploader';

type DocumentsFormUploaderProps = {
  label?: string;
};

const DocumentsFormUploader = ({ label = 'Supporting documents' }: DocumentsFormUploaderProps) => {
  const form = Form.useFormInstance();

  return (
    <Form.Item label={label}>
      <Form.List name="documents">
        {(documents, { remove }) => (
          <>
            {documents.map((document) => (
              <DocumentUploader
                key={document.name}
                form={form}
                document={document}
                remove={remove}
              />
            ))}
          </>
        )}
      </Form.List>

      <Upload
        fileList={[]}
        beforeUpload={(file) => {
          form.setFieldsValue({
            documents: [...(form.getFieldValue('documents') || []), { file }]
          });
          return false;
        }}
      >
        <Button icon={<UploadOutlined />}>Attach a file</Button>
      </Upload>
    </Form.Item>
  );
};

export default DocumentsFormUploader;
