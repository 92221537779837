import { Switch, SwitchProps } from 'antd';

type ActiveSwitchProps = {
  activeKey?: string | null;
  active: boolean;
  updateActive: SwitchProps['onChange'];
};
const ActiveSwitch = ({ activeKey = null, active = false, updateActive }: ActiveSwitchProps) => {
  if (activeKey == null) {
    return <Switch checked disabled />;
  }

  return <Switch checked={active} onChange={updateActive} />;
};

export default ActiveSwitch;
