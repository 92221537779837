import React from 'react';
import PropTypes from 'prop-types';
import { Divider, Space, Switch, Tooltip, Typography } from 'antd';
import { t } from '@gowgates/utils';

export const SectionDivider = ({ labelKey, toggleKey, fileConfig, updateFileConfigVal }) =>
  fileConfig ? (
    <Divider orientation="left">
      <Space size="middle" align="baseline">
        <Typography.Paragraph editable={{ onChange: (val) => updateFileConfigVal(labelKey, val) }}>
          {fileConfig[labelKey]}
        </Typography.Paragraph>

        {toggleKey && (
          <Tooltip
            title={t(`globals.${fileConfig[toggleKey] ? 'section_enabled' : 'section_disabled'}`)}
          >
            <Switch
              checked={fileConfig[toggleKey]}
              size="small"
              onChange={(val) => updateFileConfigVal(toggleKey, val)}
            />
          </Tooltip>
        )}
      </Space>
    </Divider>
  ) : null;

SectionDivider.propTypes = {
  fileConfig: PropTypes.object,
  updateFileConfigVal: PropTypes.func.isRequired,
  labelKey: PropTypes.string.isRequired,
  toggleKey: PropTypes.string
};
