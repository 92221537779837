import { Switch, SwitchProps } from 'antd';

type RequiredSwitchProps = {
  requiredKey?: string | null;
  active?: boolean;
  required?: boolean;
  updateRequired: SwitchProps['onChange'];
};
const RequiredSwitch = ({
  requiredKey = null,
  active = false,
  required = false,
  updateRequired
}: RequiredSwitchProps) => {
  if (requiredKey == null) {
    return <Switch checked disabled />;
  }

  if (!active) {
    return <Switch disabled />;
  }

  return <Switch checked={required} onChange={updateRequired} />;
};

export default RequiredSwitch;
