import React from 'react';
import PropTypes from 'prop-types';
import { t } from '@gowgates/utils';

const ButtonActionCol = ({ buttonAction, taskStructure, taskStructures = [] }) => {
  const findTaskStructure = (id) =>
    taskStructures?.find((taskStructure) => taskStructure.id === id)?.name;

  if (buttonAction.type === 'system') {
    return t(`buttonAction.actions.${buttonAction.action}`);
  }

  if (buttonAction.type === 'abort_task' || buttonAction.type === 'create_task') {
    return findTaskStructure(buttonAction.relatedTaskStructureId);
  }

  return (
    <>
      {t(`activerecord.attributes.${taskStructure.entity}.${buttonAction.field}`)}
      {buttonAction.fieldChangeType === 'hardcoded' ? (
        <>
          <>: </>
          {buttonAction.fieldValue}
        </>
      ) : (
        <>
          <>: (</>
          {t(`buttonAction.fieldChangeTypes.${buttonAction.fieldChangeType}`)}
          <>)</>
        </>
      )}
    </>
  );
};

ButtonActionCol.propTypes = {
  buttonAction: PropTypes.object.isRequired,
  taskStructure: PropTypes.object.isRequired,
  taskStructures: PropTypes.array
};

export default ButtonActionCol;
