import { Typography } from 'antd';

import { RichTextHtml } from '@gowgates/core';

import { Field } from '../../../types';

export const Description = ({ field }: { field: Field }) => {
  if (!field.description) {
    return null;
  }

  return (
    <span>
      <Typography.Text type="secondary">
        <RichTextHtml Component="span" noStyle htmlText={field.description} />
      </Typography.Text>
    </span>
  );
};
