import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router';
import { isUnprocessableEntity } from '@gowgates/api-client';
import { addErrorsFromAPIInForm, scrollToFirstError } from '@gowgates/utils';
import { sendClaimAction } from '../../../api/endpoints';
import { CreateStepsContext } from '../../../contexts/CreateStepsContext';
import useClaim from '../../../hooks/useClaim';
import Footer from './Footer';

const CreateClaimForm = ({ nestedModels, children }) => {
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const { currentTab, nextTab } = useContext(CreateStepsContext);
  const navigate = useNavigate();
  const [isUpdating, setUpdating] = useState(false);
  const { claim } = useClaim();

  const { isPending: isLoading, mutate } = useMutation({
    mutationFn: (values) => sendClaimAction(claim.id, currentTab.action, values),
    onError: (error) => {
      addErrorsFromAPIInForm({ error, form, extraNodes: nestedModels });

      if (isUnprocessableEntity(error)) {
        scrollToFirstError();
      }
    },
    onSuccess: () => {
      if (nextTab.path) {
        queryClient.invalidateQueries({ queryKey: ['claim', claim.id] });
        navigate(`../${nextTab.path}`);
      } else {
        window.location = `/claims/${claim.id}`;
      }
    }
  });

  return (
    <Form
      form={form}
      initialValues={claim}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 13 }}
      labelWrap
      onFinish={mutate}
      disabled={isLoading || isUpdating}
    >
      {typeof children === 'function' ? children(setUpdating) : children}

      <Footer form={form} />
    </Form>
  );
};

CreateClaimForm.propTypes = {
  nestedModels: PropTypes.array,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func])
};

export default CreateClaimForm;
