import { ThemeConfig } from 'antd';
import { createContext } from 'react';

import { InputFormItemProps } from '../../layouts/auth/components/InputFormItem';

import { User as BaseUser, User } from '../../types';

export type AuthContextUser = BaseUser & Record<string, any>;
export type AuthLayoutConfigs = {
  theme?: ThemeConfig;
  title?: {
    overrides?: {
      className?: string;
    };
  };
  footer?: {
    overrides?: {
      className?: string;
    };
  };
  forms?: {
    formItem?: {
      mode?: InputFormItemProps['mode'];
    };
  };
};

export type AuthContextProps = {
  layoutConfigs?: AuthLayoutConfigs;
  currentUser?: AuthContextUser;
  isUserSignedIn?: boolean;
  updateUserInStorage: (user: User) => void;
  addUserToStorage: (user: User, authorization?: string) => void;
  setAfterLoginPath: (path: string) => void;
  digestAfterLoginPath: () => string;
  handleLogout: (props?: { searchString?: string }) => void;
};

export const defaultAuthConfigs: AuthContextProps = {
  layoutConfigs: { forms: { formItem: {} } },
  isUserSignedIn: false,
  updateUserInStorage: () => {},
  addUserToStorage: () => {},
  setAfterLoginPath: () => {},
  digestAfterLoginPath: () => '/',
  handleLogout: () => {}
};

export const AuthContext = createContext<AuthContextProps>(defaultAuthConfigs);
