import qs from 'query-string';
import { Link } from 'react-router-dom';
import { Skeleton } from 'antd';

import { AnyObject } from '@gowgates/utils';

import Box from '../../components/Box';

type StatisticBoxProps = {
  value?: number;
  label?: string;
  params?: AnyObject;
  path?: string;
  loading?: boolean;
  numberClassName?: string;
};
const StatisticBox = ({
  value = 0,
  label = '',
  params = {},
  path = 'tasks',
  loading = false,
  numberClassName = ''
}: StatisticBoxProps) => (
  <Link to={`/${path}?${qs.stringify(params)}`}>
    <Box className="statistic-box">
      <div className={`statistic-count ${numberClassName}`}>
        {loading ? <Skeleton.Avatar active shape="square" /> : value}
      </div>
      <h4 className="statistic-title">{label}</h4>
    </Box>
  </Link>
);

export default StatisticBox;
