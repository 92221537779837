import React from 'react';
import PropTypes from 'prop-types';
import { Button, App, Popconfirm, Space } from 'antd';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { t } from '@gowgates/utils';
import {
  confirmUser,
  unlockUser,
  sendConfirmationToUser,
  sendUnlockToUser
} from '../../../api/endpoints';

const UserActions = ({ user }) => {
  const { message } = App.useApp();
  const queryClient = useQueryClient();

  const onSuccess = (data, text) => {
    queryClient.setQueryData(['user', user.id], data);
    message.success(text);
  };

  const confirmUserMutation = useMutation({
    mutationFn: () => confirmUser(user.id),
    onSuccess: (data) => onSuccess(data, t('user.confirmConfirmation'))
  });

  const sendConfirmationInstructionsMutation = useMutation({
    mutationFn: () => sendConfirmationToUser(user.id),
    onSuccess: () => message.success(t('user.resendConfirmationInstructionsSuccess'))
  });

  const unlockUserMutation = useMutation({
    mutationFn: () => unlockUser(user.id),
    onSuccess: (data) => onSuccess(data, t('user.unlockSuccess'))
  });

  const sendUnlockInstructionsMutation = useMutation({
    mutationFn: () => sendUnlockToUser(user.id),
    onSuccess: () => message.success(t('devise.unlocks.sendInstructions'))
  });

  return (
    <Space>
      {user.permissions.confirm && (
        <Popconfirm
          title={t('globals.areYouSure')}
          onConfirm={confirmUserMutation.mutate}
          okText={t('globals.yesNo.true')}
          cancelText={t('globals.yesNo.false')}
          placement="bottomRight"
        >
          <Button>{t('user.confirm')}</Button>
        </Popconfirm>
      )}

      {user.permissions.sendConfirmationInstructions && (
        <Popconfirm
          title={t('globals.areYouSure')}
          onConfirm={sendConfirmationInstructionsMutation.mutate}
          okText={t('globals.yesNo.true')}
          cancelText={t('globals.yesNo.false')}
          placement="bottomRight"
        >
          <Button type="primary">
            {t('devise.confirmations.new.resendConfirmationInstructions')}
          </Button>
        </Popconfirm>
      )}

      {user.permissions.unlock && (
        <Popconfirm
          title={t('globals.areYouSure')}
          onConfirm={unlockUserMutation.mutate}
          okText={t('globals.yesNo.true')}
          cancelText={t('globals.yesNo.false')}
          placement="bottomRight"
        >
          <Button>{t('user.unlock')}</Button>
        </Popconfirm>
      )}

      {user.permissions.sendUnlockInstructions && (
        <Popconfirm
          title={t('globals.areYouSure')}
          onConfirm={sendUnlockInstructionsMutation.mutate}
          okText={t('globals.yesNo.true')}
          cancelText={t('globals.yesNo.false')}
          placement="bottomRight"
        >
          <Button type="primary">{t('devise.unlocks.new.resendUnlockInstructions')}</Button>
        </Popconfirm>
      )}
    </Space>
  );
};

UserActions.propTypes = {
  user: PropTypes.object.isRequired
};

export default UserActions;
