import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Form } from 'antd';
import { t } from '@gowgates/utils';
import ItemModalContent from './ItemModalContent';

const ItemDetailsModal = ({ isOpen = false, setOpen, item, structure }) => {
  const [isEditing, setEditing] = useState(false);
  const [form] = Form.useForm();
  const [activeTab, setActiveTab] = useState('details');

  useEffect(() => {
    if (isOpen) {
      setEditing(false);
      setActiveTab('details');
    }
  }, [isOpen, form]);

  useEffect(() => {
    if (isEditing) form.resetFields();
  }, [isEditing, form]);

  const closeModal = () => {
    setOpen(false);
    setEditing(false);
  };

  return (
    <Modal
      title={t('item.id', { id: item.id })}
      open={isOpen}
      onCancel={closeModal}
      footer={null}
      width={700}
    >
      <ItemModalContent
        form={form}
        closeModal={closeModal}
        item={item}
        structure={structure}
        isEditing={isEditing}
        setEditing={setEditing}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
    </Modal>
  );
};

ItemDetailsModal.propTypes = {
  isOpen: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  structure: PropTypes.object.isRequired
};

export default ItemDetailsModal;
