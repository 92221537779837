import { Dropdown, MenuProps } from 'antd';
import { MoreOutlined } from '@ant-design/icons';

type PageContextMenuProps = {
  items?: MenuProps['items'];
};

const PageContextMenu = ({ items = [] }: PageContextMenuProps) =>
  items?.length ? (
    <Dropdown menu={{ items }} trigger={['click']}>
      <MoreOutlined />
    </Dropdown>
  ) : null;

export default PageContextMenu;
