import { QuillOptions } from 'quill';
import { Form, GetProps } from 'antd';

import { SLIM_TEXT_QUILL_MODULES } from '@gowgates/utils';

import { useQuillFormItem } from '../../hooks';

type AntFormItemProps = GetProps<typeof Form.Item>;
type CgwQuillProps = Pick<AntFormItemProps, 'id'> & {
  hasError?: boolean;
  tabIndex?: number;
} & QuillOptions;

export const CgwQuill = ({
  hasError = false,
  id,
  tabIndex,
  modules = SLIM_TEXT_QUILL_MODULES,
  ...quillOptions
}: CgwQuillProps) => {
  const { quillRef } = useQuillFormItem({
    formItemId: id,
    modules,
    ...quillOptions
  });

  return (
    <div className={`quill${hasError ? ' error' : ''}`}>
      <div ref={quillRef} tabIndex={tabIndex} />
    </div>
  );
};
