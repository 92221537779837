import { PropsWithChildren, useCallback, useState } from 'react';
import Cookies from 'js-cookie';

import { apiConfigs } from '@gowgates/api-client';

import { AuthContext, AuthContextProps, AuthContextUser as User } from './context';

const getUserFromStorage = () => {
  const userData = localStorage.getItem('user');

  return userData ? (JSON.parse(userData) as User) : undefined;
};

export const AuthProvider = ({
  children,
  layoutConfigs
}: PropsWithChildren<Pick<AuthContextProps, 'layoutConfigs'>>) => {
  const [currentUser, setCurrentUser] = useState(getUserFromStorage());

  const clearUser = () => {
    localStorage.removeItem('user');
    Cookies.remove(apiConfigs().authTokenKey, { domain: apiConfigs().domain });

    setCurrentUser(undefined);
  };

  const updateUserInStorage = (user: User) => {
    localStorage.setItem('user', JSON.stringify(user));

    setCurrentUser(user);
  };

  const addUserToStorage = (user: User, authorization?: string) => {
    if (!user || !authorization) return;

    Cookies.set(apiConfigs().authTokenKey, authorization, { domain: apiConfigs().domain });
    localStorage.setItem('user', JSON.stringify(user));
    setCurrentUser(user);
  };

  const setAfterLoginPath = (path: string) => {
    localStorage.setItem('afterLoginPath', path);
  };

  const digestAfterLoginPath = useCallback(() => {
    const afterLoginPath = localStorage.getItem('afterLoginPath') || '/';

    localStorage.removeItem('afterLoginPath');
    return afterLoginPath;
  }, []);

  const handleLogout = ({ searchString = '' }: { searchString?: string } = {}) => {
    clearUser();

    window.location.href = `/login${searchString}`;
  };

  return (
    <AuthContext.Provider
      value={{
        layoutConfigs,
        currentUser,
        isUserSignedIn: Boolean(currentUser),
        updateUserInStorage,
        addUserToStorage,
        setAfterLoginPath,
        digestAfterLoginPath,
        handleLogout
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
