import React from 'react';
import { Form, Row, Col, Button, App, Input } from 'antd';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router';
import { FormItem } from '@gowgates/dynamic-fields';
import { addErrorsFromAPIInForm, t } from '@gowgates/utils';
import { RichTextHtml, useAuth } from '@gowgates/core';
import { usePageTitle } from '@gowgates/core';
import { createOtp, getOtpQrCode } from '../../api/endpoints';
import Box from '../../components/Box';
import Page from '../../components/Page';

const Profile = () => {
  const { message } = App.useApp();
  const { currentUser } = useAuth();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  usePageTitle(t('user.otp.enable'));

  const { data: svg } = useQuery({ queryKey: ['qrCode'], queryFn: getOtpQrCode });

  const { isPending: isLoading, mutate } = useMutation({
    mutationFn: createOtp,
    onError: (error) => addErrorsFromAPIInForm({ error, form }),
    onSuccess: (data) => {
      message.success(t('user.otp.enabled'));
      navigate('/profile');
      localStorage.setItem('user', JSON.stringify(data));
    }
  });

  if (currentUser.otpRequiredForLogin) {
    navigate('/profile');
  }

  return (
    <Page title={t('user.otp.enable')} backUrl="/profile" overlap>
      <Col span={18} offset={3}>
        <Form form={form} layout="vertical" onFinish={mutate} disabled={isLoading}>
          <Box>
            <Row>
              <Col span={16} offset={3}>
                <RichTextHtml
                  Component="p"
                  noStyle
                  className="text-center"
                  htmlText={t('user.otp.install')}
                />

                <RichTextHtml variant="otp-svg" className="text-center" htmlText={svg} />
              </Col>

              <Col span={16} offset={3}>
                <FormItem name="code" label="Code">
                  <Input />
                </FormItem>
              </Col>
            </Row>

            <footer className="d-flex justify-content-end">
              <Button type="primary" htmlType="submit">
                {t('globals.save')}
              </Button>
            </footer>
          </Box>
        </Form>
      </Col>
    </Page>
  );
};

export default Profile;
