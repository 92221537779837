import { DynamicFormItem } from '@gowgates/dynamic-fields';

import useFileConfig from '../../../hooks/useFileConfig';

type ClaimantDetailsFormProps = {
  nested: boolean;
};

const ClaimantDetailsForm = ({ nested = false }: ClaimantDetailsFormProps) => {
  const { claimantStructure } = useFileConfig();
  const namespace = nested ? ['claimant', 'data'] : ['data'];

  return claimantStructure?.map((field) => (
    <DynamicFormItem key={field.name} field={field} namespace={namespace} />
  ));
};

export default ClaimantDetailsForm;
