import { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';

import { Claim } from '../../types';

type ClaimColProps = PropsWithChildren<{ claim: Claim }>;

const ClaimCol = ({ claim, children }: ClaimColProps) => {
  if (claim.permissions.show) {
    return (
      <Link to={`/claims/${claim.id}`} className="col-clickable">
        {children}
      </Link>
    );
  }

  return children;
};

export default ClaimCol;
