import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { App, Button, Tooltip } from 'antd';
import { useMutation, useQueryClient, MutationFunction, QueryKey } from '@tanstack/react-query';
import { t } from '@gowgates/utils';

type DangerPopconfirmProps = {
  deleteFn: MutationFunction;
  invalidateQueries?: QueryKey;
  title?: string;
  permission?: boolean;
  async?: boolean;
  onSuccess?: () => void;
  onError?: (e: any) => void;
  tooltip?: boolean;
  tooltipMessage?: string;
  setQueryData?: QueryKey;
  children?: any;
};

// TODO: This component doesn't seem to make sense anymore.
//       If there is a way to get the promise from it should be used in the onOk() as docs here:
//       https://ant.design/components/modal#components-modal-demo-confirm
//       alternative is to use a normal modal and control the open prop like it was doing
//       here when using popconfirm (see git history)
export const ExtendedDangerPopconfirm = ({
  deleteFn,
  invalidateQueries,
  setQueryData,
  title = 'Are you sure?',
  permission = false,
  async = true,
  children,
  onSuccess = () => {},
  onError = () => {},
  tooltip = true,
  tooltipMessage
}: DangerPopconfirmProps) => {
  const queryClient = useQueryClient();
  const { modal } = App.useApp();

  const { mutateAsync } = useMutation({
    mutationFn: deleteFn,
    onSuccess: (data) => {
      if (invalidateQueries) {
        queryClient
          .invalidateQueries({
            queryKey: invalidateQueries
          })
          .then(() => {
            onSuccess();
          });
      } else if (setQueryData) {
        queryClient.setQueryData(setQueryData, data);
        onSuccess();
      } else {
        onSuccess();
      }
    },
    onError: (error) => {
      onError(error);
    }
  });

  if (!permission) {
    return null;
  }

  const confirmDestroy = async () => {
    if (async) {
      return mutateAsync(undefined);
    } else {
      deleteFn(undefined);
    }
  };

  const openModal = () => {
    modal.confirm({
      title,
      icon: <ExclamationCircleOutlined />,
      okText: t('globals.yesNo.true'),
      okType: 'danger',
      cancelText: t('globals.yesNo.false'),
      onOk() {
        return confirmDestroy();
      }
    });
  };

  const tooltipTitle = tooltip ? tooltipMessage || t('globals.delete') : null;

  return (
    <Tooltip title={tooltipTitle}>
      {
        // If there are children injects onClick prop and renders, otherwise renders default
        children ? (
          { ...children, props: { ...children.props, onClick: openModal } }
        ) : (
          <Button
            danger
            type="text"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();

              openModal();
            }}
          >
            <DeleteOutlined />
          </Button>
        )
      }
    </Tooltip>
  );
};
