import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Space } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { t } from '@gowgates/utils';
import { DateFormatter } from '@gowgates/dynamic-fields';
import { UserAvatar } from '@gowgates/core';

import UserConfirmedTag from './UserConfirmedTag';
import UserBlockedTag from './UserBlockedTag';
import UserLoginLockedTag from './UserLoginLockedTag';

const UserSummary = ({ user }) => (
  <aside className="page-summary">
    <Space direction="vertical" size="middle" className="w-100">
      {!user.otpRequiredForLogin && (
        <Alert
          description={t('user.otpDisabled')}
          type="error"
          showIcon
          icon={<ExclamationCircleOutlined />}
        />
      )}
      <Space direction="vertical" className="text-center" style={{ width: '100%' }}>
        <UserAvatar size={60} user={user} />
        <p>{user.email}</p>
      </Space>

      <UserConfirmedTag user={user} />
      <UserBlockedTag status={user.status} />
      <UserLoginLockedTag locked={user.locked} />

      <div>
        <dl>
          <dt>{t('activerecord.attributes.user.createdAt')}</dt>
          <dd>
            <DateFormatter value={user.createdAt} />
          </dd>
        </dl>
      </div>
    </Space>
  </aside>
);

UserSummary.propTypes = {
  user: PropTypes.object.isRequired
};

export default UserSummary;
