import { Avatar, AvatarProps } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import { UserWithExtraOptionals } from '../../types';

type UserAvatarProps = {
  loading?: boolean;
  user?: Pick<UserWithExtraOptionals, 'firstName' | 'lastName' | 'avatar'>;
  size?: AvatarProps['size'];
} & { className?: string };

export const UserAvatar = ({
  user,
  size = 'small',
  loading = false,
  className
}: UserAvatarProps) => {
  if (!user) {
    return null;
  }

  if (user.avatar) {
    return (
      <Avatar
        size={size}
        className={className}
        src={loading ? null : user.avatar.url}
        icon={loading ? <LoadingOutlined /> : null}
      />
    );
  }

  return (
    <Avatar size={size} className={className} icon={loading ? <LoadingOutlined /> : null}>
      {user.firstName[0]}
      {user.lastName[0]}
    </Avatar>
  );
};
