import React from 'react';
import { Flex, Form, Spin } from 'antd';

import { labelForAttributeModel } from '@gowgates/utils';

const rules = [{ validator: async () => null }];

type AntFormItemProps = React.ComponentProps<typeof Form.Item>;
export type FormItemProps = AntFormItemProps & { model?: string; isLoading?: boolean };

export const FormItem = ({
  name,
  model,
  children,
  label,
  isLoading = false,
  ...rest
}: FormItemProps) => {
  const finalLabel = labelForAttributeModel({
    label,
    name,
    model
  });

  return (
    <Form.Item name={name} label={finalLabel} rules={rules} {...rest}>
      {isLoading ? (
        <Flex align="center" justify="center">
          <Spin />
        </Flex>
      ) : (
        children
      )}
    </Form.Item>
  );
};
