import React from 'react';
import PropTypes from 'prop-types';
import { Divider, Form } from 'antd';
import ItemDescriptions from '../../../../descriptions/Item';
import ItemActions from './ItemActions';
import ItemDocuments from './ItemDocuments';
import ItemPaymentFields from './ItemPaymentFields';
import ItemNotes from './ItemNotes';

const ItemModalDetails = ({
  item,
  sectionStructure,
  onSuccess = () => {},
  setEditing,
  form,
  documents = [],
  notes = []
}) => (
  <>
    <ItemDescriptions item={item} structure={sectionStructure} />

    <ItemDocuments documentsCount={item.documentsCount} documents={documents} />

    <Divider orientation="left">Payment details</Divider>

    <Form form={form} initialValues={item} layout="vertical">
      <ItemPaymentFields item={item} />
    </Form>

    <ItemActions item={item} setEditing={setEditing} onSuccess={onSuccess} />

    <ItemNotes notesCount={item.notesCount} notes={notes} />
  </>
);

ItemModalDetails.propTypes = {
  onSuccess: PropTypes.func,
  item: PropTypes.object.isRequired,
  sectionStructure: PropTypes.object.isRequired,
  setEditing: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  notes: PropTypes.array,
  documents: PropTypes.array
};

export default ItemModalDetails;
