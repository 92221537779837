import { Typography } from 'antd';

import { formatDate } from '@gowgates/utils';

type TwoLineDateProps = {
  date?: string;
};

const TwoLineDate = ({ date }: TwoLineDateProps) => {
  if (!date) {
    return null;
  }

  return (
    <p>
      {formatDate(date)}
      <br />
      <Typography.Text type="secondary">
        <small>{formatDate(date, 'time')}</small>
      </Typography.Text>
    </p>
  );
};

export default TwoLineDate;
