import { useEffect, useRef } from 'react';
import { Form, Modal, Input, InputRef } from 'antd';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router';
import { FormItem } from '@gowgates/dynamic-fields';
import { addErrorsFromAPIInForm, t } from '@gowgates/utils';
import { createClaimStructure } from '../../api/endpoints';
import ModalFormFooter from '../../components/ModalFormFooter';

type NewClaimStructureModalProps = {
  isOpen?: boolean;
  setOpen: (o: boolean) => void;
};

const NewClaimStructureModal = ({ isOpen = false, setOpen }: NewClaimStructureModalProps) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const inputRef = useRef<InputRef>(null);

  useEffect(() => {
    if (isOpen) {
      inputRef?.current?.focus();
    }
  }, [isOpen]);

  const closeModal = () => {
    form.resetFields();
    setOpen(false);
  };

  const { isPending: isLoading, mutate } = useMutation({
    mutationFn: (values) => createClaimStructure(values),
    onError: (error) => addErrorsFromAPIInForm({ error, form }),
    onSuccess: (data) => {
      closeModal();
      navigate(`${data.id}`);
    }
  });

  return (
    <Modal title={t('claimStructure.new')} open={isOpen} onCancel={closeModal} footer={null}>
      <Form form={form} layout="vertical" onFinish={mutate} disabled={isLoading}>
        <FormItem name="name" model="claimStructure" required>
          <Input ref={inputRef} />
        </FormItem>

        <ModalFormFooter closeModal={closeModal} isLoading={isLoading} />
      </Form>
    </Modal>
  );
};

export default NewClaimStructureModal;
