import React, { PropsWithChildren } from 'react';
import { AvatarProps, Space, Typography } from 'antd';

import { UserAvatar } from '../UserAvatar';
import { UserWithExtraOptionals } from '../../types';

type AvatarAndNameProps = {
  user?: UserWithExtraOptionals;
  defaultText?: string;
  avatarSize?: AvatarProps['size'];
  hideAvatar?: boolean;
  showRole?: boolean;
  TooltipComponent?: React.FC<PropsWithChildren<{ title?: string }>>;
};

export const AvatarAndName = ({
  user,
  defaultText = '',
  avatarSize = 'small',
  hideAvatar = false,
  showRole = false,
  TooltipComponent = ({ children }) => children
}: AvatarAndNameProps) => {
  if (!user || !user.id) {
    return (
      <Typography.Text type="secondary">
        <em>{defaultText}</em>
      </Typography.Text>
    );
  }

  return (
    <Space>
      {!hideAvatar && <UserAvatar user={user} size={avatarSize} />}
      <TooltipComponent title={`${user.name}${showRole && user.role ? ` - ${user.role}` : ''}`}>
        <div>
          <span>{user.name}</span>
          {showRole && user.role && (
            <Typography.Text type="secondary">
              <span> - </span>
              <small>
                <em>{user.role}</em>
              </small>
            </Typography.Text>
          )}
        </div>
      </TooltipComponent>
    </Space>
  );
};
